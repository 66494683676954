<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center">
        <v-dialog v-model="modalOpen" persistent max-width="450px">
          <v-card>
            <v-card-title>
              <span class="primary--text">Cambiar contraseña</span>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    v-model="currentPassword"
                    type="password"
                    label="Contraseña actual"
                    required
                    outlined
                    dense
                    :rules="currentPassRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    type="password"
                    label="Nueva contraseña"
                    hint="Escriba una contraseña"
                    required
                    outlined
                    dense
                    v-model="newPassword"
                    :rules="newPassRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    type="password"
                    label="Confirmar contraseña"
                    hint="Repita su nueva contraseña"
                    required
                    outlined
                    dense
                    v-model="confirmPassword"
                    :rules="confirmPassRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModal">
                Cerrar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveNewPassword"
                :disabled="!valid"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios/axios-api";
export default {
  name: "ChangePassword",
  data() {
    return {
      valid: false,
      modalOpen: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      currentPassRules: [(v) => !!v || "Ingrese su contraseña"],
      newPassRules: [(v) => !!v || "Ingrese nueva contraseña"],
      confirmPassRules: [
        (v) => !!v || "Confirme nueva contraseña",
        (v) => v === this.newPassword || "Las contraseñas no coinciden",
      ],
    };
  },
  created() {
    this.$bus.$on("openModal", (data) => {
      this.modalOpen = data;
    });
  },
  methods: {
    ...mapGetters({
      user: "user/user",
    }),
    closeModal() {
      this.modalOpen = false;
      this.$emit("close", false);
      this.$refs.form.reset();
    },
    saveNewPassword() {
      if (this.$refs.form.validate()) {
        axios
          .post(`api/SysUser/changepass?currentPass=${this.currentPassword}&newPass=${this.newPassword}`)
          .then((passwordChanged) => {
            if (passwordChanged) {
              this.$swal.fire({
                icon: "success",
                title: "Su contraseña ha sido guardada",
                showConfirmButton: false,
                timer: 3000,
              });
              this.closeModal();
            }
          })
          .catch((e) => {
            this.$swal.fire({
              icon: "error",
              title: e.response.data.error[0],
              showConfirmButton: false,
              timer: 3000,
            });
          });
      }
    },
  },
};
</script>