import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import configAppBenef from "./modules/appBenef/configuracion";
import prestadores from "./modules/prestadores";
import shared from "./modules/shared";
import filters from "./modules/filters";
import appBenef from "./modules/appBenef";
import cuotas from "./modules/cuotas";
import AdministracionSistema from "./modules/administracion-sistema";
import email from "./modules/email";
import mesaEntrada from "./modules/mesaEntrada";
import afiliaciones from "./modules/afiliaciones";
import configAfiliaciones from "./modules/afiliaciones/configuracion";
import solicitudesAfiliacion from "./modules/afiliaciones/solicitudesAfiliacion";
import proveedores from "./modules/proveedores";
import convenios from "./modules/convenios";
import configConvenios from "./modules/convenios/configuracion";
import analytics from "./modules/analytics";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    prestadores,
    shared,
    filters,
    appBenef,
    cuotas,
    configAppBenef,
    AdministracionSistema,
    email,
    mesaEntrada,
    afiliaciones,
    configAfiliaciones,
    solicitudesAfiliacion,
    proveedores,
    convenios,
    configConvenios,
    analytics
  }
});
