import axios from "@/axios/axios-api";
const actions = {
  async getTiposDocumentos() {
    const response = await axios.get("api/tiposDocumentos");
    return response.data.data;
  },
  async postTipoDocumento(context, payload) {
    const response = await axios.post("api/tiposDocumentos", payload);
    return response;
  },
  async deleteTipoDocumento(context, payload) {
    const response = await axios.delete(`api/tiposDocumentos/${payload.tdId}`);
    return response;
  },

  // WEB_PARENTESCOS
  async getParentescos() {
    const response = await axios.get("api/Parentescos");
    return response.data.data;
  },
  async postParentescos(context, payload) {
    const response = await axios.post("api/Parentescos", payload);
    return response;
  },
  async deleteParentescos(context, payload) {
    const response = await axios.delete(`api/Parentescos/${payload}`);
    return response;
  },

  //MOTIVO_DE_BAJA

  async getMotivosDeBaja() {
    const response = await axios.get("api/motivosDeBaja");
    return response.data.data;
  },
  async getTiposMovBaja() {
    const response = await axios.get("api/taxonomy/TiposMov");
    return response.data.data;
  },
  async postMotivosDeBaja(context, payload) {
    const response = await axios.post("api/motivosDeBaja", payload);
    return response;
  },
  async deleteMotivoDeBaja(context, payload) {
    const response = await axios.delete(`api/motivosDeBaja/${payload}`);
    return response;
  },

  //ESTADOS_CIVILES

  async getEstadosCiviles() {
    const response = await axios.get("api/EstadosCiviles");
    return response.data.data;
  },
  async postEstadosCiviles(context, payload) {
    const response = await axios.post("api/EstadosCiviles", payload);
    return response;
  },
  async deleteEstadosCiviles(context, payload) {
    const response = await axios.delete(`api/EstadosCiviles/${payload}`);
    return response;
  },
  //DEPENDENCIAS

  async getDependencias() {
    const response = await axios.get("api/Dependencias");
    return response.data.data;
  },
  async postDependencia(context, payload) {
    const response = await axios.post("api/Dependencias", payload);
    return response;
  },
  async deleteDependencia(context, payload) {
    const response = await axios.delete(`api/Dependencias/${payload.depBenId}`);
    return response;
  },
  //Nacionalidades
  async getNacionalidades() {
    const response = await axios.get("api/nacionalidades");
    return response.data.data;
  },
  async saveNacionalidades(context, payload) {
    const response = await axios.post("api/nacionalidades", payload);
    return response;
  },
  async deleteNacionalidad(context, payload) {
    const response = await axios.delete(`api/nacionalidades/${payload.nacId}`);
    return response;
  },

  //Obra sociales del rnos
  async getRnos() {
    const response = await axios.get("api/rnos");
    return response.data.data;
  },
  async saveObraSocial(context, payload) {
    const response = await axios.post("api/rnos", payload);
    return response;
  },
  async deleteObraSocial(context, payload) {
    const response = await axios.delete(`api/rnos/${payload.rnosId}`);
    return response;
  },

  async getDelegacionesByRnos(context, payload) {
    const response = await axios.get(`api/rnos/DelegacionesByRnos/${payload}`);
    return response.data.data;
  },

  async saveDelegacion(context, payload) {
    const response = await axios.post("api/rnos/DelegacionRNOS", payload);
    return response;
  },
  async deleteDelegacion(context, payload) {
    const response = await axios.delete(`api/rnos/DelegacionRNOS/${payload}`);
    return response;
  },

  async getRegimenesByRnos(context, payload) {
    const response = await axios.get(`api/rnos/RegimenesByRnos/${payload}`);
    return response.data.data;
  },
  async saveRegimen(context, payload) {
    const response = await axios.post("api/rnos/regimenRNOS", payload);
    return response;
  },
  async deleteRegimen(context, payload) {
    const response = await axios.delete(
      `api/rnos/RegimenRNOS/${payload.regId}`
    );
    return response;
  },
  // Gerenciadoras
  async getGerenciadoras(context, payload) {
    const response = await axios.get(`api/gerenciadoras/gerenciadoras`);
    return response.data.data;
  },
  async saveGerenciadoras(context, payload) {
    const response = await axios.post("api/gerenciadoras", payload);
    return response;
  },
  async deleteGerenciadora(context, payload) {
    const response = await axios.delete(
      `api/gerenciadoras/DeleteGerenciadora/${payload.gerId}`
    );
    return response;
  },
  // Niveles atención
  async getNivelesDeAtencion(context, payload) {
    const response = await axios.get(`api/nivelesDeAtencion/nivelesAtencion`);
    return response.data.data;
  },
  async saveNivelesDeAtencion(context, payload) {
    const response = await axios.post("api/NivelesDeAtencion", payload);
    return response;
  },
  async deleteNivelAtencion(context, payload) {
    const response = await axios.delete(
      `api/nivelesDeAtencion/deleteNivelAtencion/${payload.nivelId}`
    );
    return response;
  },

  // Tipos beneficiarios
  async getTiposBeneficiarios() {
    const response = await axios.get("api/tiposBeneficiarios");
    return response.data.data;
  },
  async saveTiposBeneficiarios(context, payload) {
    const response = await axios.post("api/tiposBeneficiarios", payload);
    return response;
  },
  async deleteTipoBeneficiario(context, payload) {
    const response = await axios.delete(
      `api/tiposBeneficiarios/${payload.tipoBenId}`
    );
    return response;
  },
  // tipo de afiliacion
  async getOtrasAfiliaciones() {
    const response = await axios.get("api/otrasAfiliaciones");
    return response.data.data;
  },
  async saveOtrasAfiliaciones(context, payload) {
    const response = await axios.post("api/otrasAfiliaciones", payload);
    return response;
  },
  async deleteOtrasAfiliaciones(context, payload) {
    const response = await axios.delete(
      `api/otrasAfiliaciones/${payload.otroTipoNumeroAfiliadoId}`
    );
    return response;
  },

  // Solicitudes de Afiliacion
  async getPermissionsEstadosSolicitudes(context, payload) {
    const response = await axios.get(
      `api/estadosDeSolicitudes/permisosEstadosSolicitudes/${payload.optionCode}`
    );
    return response.data.data;
  },
  async getEstadosSolicitudByTipoSolicitud(context, payload) {
    const response = await axios.get(`api/estadosDeSolicitudes/${payload}`);
    return response.data.data;
  },
  async getEstadoSolicitudById(context, payload) {
    const response = await axios.get(
      `api/estadosDeSolicitudes/estadoSolicitudById/${payload}`
    );
    return response.data.data;
  },
  async getVendedores() {
    const response = await axios.get("api/cobradores/vendedoresAfiliaciones");
    return response.data.data;
  },
  async deleteVendedores(context, payload) {
    const response = await axios.delete(
      `api/cobradores/vendedoresAfiliaciones/${payload}`
    );
    return response;
  },
  async postVendedores(context, payload) {
    const response = await axios.post(
      "api/cobradores/vendedoresAfiliaciones",
      payload
    );
    return response;
  },

  async getTiposSolicitudes() {
    const response = await axios.get(
      "api/estadosDeSolicitudes/tiposSolicitudes"
    );
    return response.data.data;
  },
  async getTiposTitulares() {
    const response = await axios.get("api/taxonomy/tiposTitulares");
    return response.data.data;
  },
  async deleteTiposSolicitudes(context, payload) {
    const response = await axios.delete(
      `api/estadosDeSolicitudes/tiposSolicitudes/${payload}`
    );
    return response;
  },

  async getTipoDeSolicitud() {
    const response = await axios.get("api/taxonomy/tipoSolicitudes");
    return response.data;
  },
  async postTipoSolicitud(context, payload) {
    const response = await axios.post(
      "api/estadosDeSolicitudes/tiposSolicitudes",
      payload
    );
    return response;
  },
  async deleteEstadosDeSolicitudes(context, payload) {
    const response = await axios.delete(`api/estadosDeSolicitudes/${payload}`);
    return response;
  },
  async postEstadosDeSolicitudes(context, payload) {
    const response = await axios.post(
      "api/estadosDeSolicitudes/estadoSolicitudConfig",
      payload
    );
    return response;
  },
  async getGruposDeUsuarios() {
    const response = await axios.get("api/taxonomy/gruposUsuario");
    return response.data.data;
  },
  async getGruposUsuarioById(context, payload) {
    const response = await axios.get(
      `api/estadosDeSolicitudes/gruposUsuario/${payload}`
    );
    return response.data.data;
  },
  async saveGruposUsuarioEstado(context, payload) {
    const response = await axios.post(
      "api/estadosDeSolicitudes/gruposUsuario",
      payload
    );
    return response;
  },
  async getDestinosEstadoById(context, payload) {
    const response = await axios.get(
      `api/estadosDeSolicitudes/destinosEstado/${payload}`
    );
    return response.data.data;
  },
  async saveDestinosEstado(context, payload) {
    const response = await axios.post(
      "api/estadosDeSolicitudes/destinosEstado",
      payload
    );
    return response;
  },
  async heredarConfiguracionTipoSolicitud(context, payload) {
    const response = await axios.post(
      "api/estadosDeSolicitudes/heredarConfiguracion",
      payload
    );
    return response;
  },
  /// Padrón mensual sss
  async getParentescosSSS(context, payload) {
    const response = await axios.get("api/parentescos/sssParentescos");
    return response.data.data;
  },
  async getParentescosSSSById(context, payload) {
    const response = await axios.get(
      `api/parentescos/sssParentescosById/${payload.id}`
    );
    return response.data.data;
  },
  async postParentescosSSS(context, payload) {
    const response = await axios.post(
      "api/parentescos/sssParentescos",
      payload
    );
    return response;
  },
  async deleteParentescosSSS(context, payload) {
    const response = await axios.delete(
      `api/parentescos/sssParentescos/${payload}`
    );
    return response;
  },
  // Provincias
  async getProvincias(context, payload) {
    const response = await axios.get("api/provincias");
    return response.data.data;
  },
  async saveProvincias(context, payload) {
    const response = await axios.post("api/provincias", payload);
    return response;
  },
  // Localidades
  async getLocalidades(context, payload) {
    const response = await axios.get("api/localidades");
    return response.data.data;
  },
  async saveLocalidades(context, payload) {
    const response = await axios.post("api/localidades", payload);
    return response;
  },
  // Forzar movimiento en padrón SSS
  async getForzarMovimientoSss(context, payload) {
    const response = await axios.get(
      `api/forzarMovimientoSSS/ForzarMovimientoSSS/${payload}`
    );
    return response.data.data;
  },
  async saveForzarMovimientoSSS(context, payload) {
    const response = await axios.post("api/forzarMovimientoSSS", payload);
    return response;
  },
  async deleteForzarMovimientoSSS(context, payload) {
    const response = await axios.delete(
      `api/forzarMovimientoSSS/DeleteForzarMovimientoSSS/${payload.benIncluirId}`
    );
    return response;
  },
  // GESTION MOVIMIENTOS AFILIADO
  async getMovimientosAfiliado(context, payload) {
    const response = await axios.get(
      `api/afiliados/movimientos?benId=${payload}`
    );
    return response.data.data;
  },
  async getTiposMovimientos() {
    const response = await axios.get("api/taxonomy/tiposMovimientos");
    return response.data.data;
  },
  async getEstadosBenef() {
    const response = await axios.get("api/taxonomy/estadosBenef");
    return response.data;
  },
  async getTiposMovMotivosBaja() {
    const response = await axios.get("api/taxonomy/TiposMovMotivosBaja");
    return response.data;
  },
  async getPlanesByConvenio(context, payload) {
    const response = await axios.post("api/taxonomy/planesByConvenio", payload);
    return response.data.data;
  },
  async getPlanesTaxonomy(context, payload) {
    const response = await axios.get("api/taxonomy/planes");
    return response.data;
  },
  async getTiposAutorizacionesTaxonomy(context, payload) {
    const response = await axios.get("api/taxonomy/TipoAuto");
    return response.data;
  },
  async saveMovimientoAfiliado(context, payload) {
    const response = await axios.post("api/afiliados/movimientos", payload);
    return response;
  },
  async deleteMovimientoAfiliado(context, payload) {
    const response = await axios.post(
      "api/afiliados/deleteMovimientos",
      payload
    );
    return response;
  },
  async getMotivosBajaByTipoBaja(context, payload) {
    const response = await axios.get(
      `api/taxonomy/motivosBajaByTipoBaja/${payload}`
    );
    return response.data;
  }
};
export default actions;
