const webSiteOptions = Object.freeze({
  CONFIGURACION_CARTILLA: "1D4B22A7-DAA2-41F8-A3A6-361C81D4C389",
  SERVIDORES_EMAIL: "D23D2BAA-6550-4A6B-990D-6A35229918BD",
  ENLACES_UTILES: "9EDEBD25-2531-49D7-B009-BCC21E35301D",
  MENU_TABS: "353371A5-37C5-4F40-AC78-A36F176C6C71",
  FORMATOS_EMAIL_APP: "CC5BDBCD-6C0E-4E60-A560-65B62A7189E8",
  PARAMETROS_GENERALES_APP: "B744B859-B306-4225-8B42-53E8869BC082",
  BOTON_DE_PAGO: "84B29A6E-274C-4059-8311-085BD51CB0C0",
  PARENTESCOS_APP: "28254D2E-6CC0-4B82-AAFC-5B2F991A49DF",
  ESTADOS_ACTAS_FISCALIZACION: "5353C046-E639-45E5-AA19-1C74A3DA6903",
  TIPOS_INTERES_DEUDA_EMPRESA: "D6590128-E5B6-4394-BFDB-814BE31EC008",
  GESTION_PROCESOS_FISCALIZACION: "08020EB4-2D71-4C07-89DB-C10075D6DA09",
  GESTION_PROCESOS_INFORME_AFIP: "CE80609A-6974-4226-9F94-F481B1F9F344",
  CONFIGURACION_FORMATOS_EMAIL_ACTA: "F9944F22-539B-40EC-B68D-1D880A5E3778",
  CONFIGURACION_FORMATOS_PDF_ACTA: "A84B3238-D876-46A7-8475-5C52BF7EA89E",
  LOTES_DEUDA: "EFFA5E8A-EA32-4F64-B92B-CEB483CDD6A3",
  ENVIOS_ENTIDADES_COBRANZAS: "A3C4D39B-D5FB-4A8B-98AB-858FDF8421A4",
  FISCALIZACION_DE_APORTES: "DAABD31D-C94C-4053-86B5-DC8E4D373389",
  CONFIGURACION_APP: "EB524EA9-789E-4392-810A-98674593E790",
  CONFIGURACION_APLICACION_MOVIL: "E28B9C4C-B3BA-4F49-A87F-22D2A1248ABE",
  CREDENCIAL_DIGITAL_APP: "5026DD9D-0689-446B-A541-71998316D099",
  TIPOS_DE_EXPEDIENTES: "248B823A-945A-4097-9CF2-7A2988807608",
  GESTION_AFILIADOS: "CCEC5ECC-DCE3-41AF-A7CD-C29DD937160F",
  CONFIGURACION_MESA_ENTRADA: "BC8E7B5D-8BC5-48FC-B992-4C1143678DAE",
  GESTION_TIPOS_EXPEDIENTES: "ABD90AE7-31B0-472C-BE50-CB0C73146E00",
  SECTORES: "39290B0F-894B-4A3D-B48E-FB8D6F8E0154",
  OBRAS_SOCIALES_MESA_ENTRADA: "45438540-69D8-4B85-AD92-7792B369F9D7",
  GENERADORES_EXPEDIENTES_MESA_ENTRADA: "D94CC24C-B148-47B0-8056-1849F72824CC",
  DESTINOS_DESPACHOS: "4533AE36-D6F8-4FFF-ABC6-87D769B588E8",
  FORMAS_PAGO: "500B9973-A1F4-4AA8-B8D1-B7CDF8E66BCE",
  FORMAS_PAGO_ENTIDADES_COBRANZA: "AC2EF35D-9339-40B2-B02C-35B44486F52A",
  ENTIDADES_FINANCIERAS: "1A2ABD2B-99C8-470D-A5DF-CC28FAA0A28A",
  TIPOS_GENERADORES_MESA_ENTRADA: "DB2B80C7-55B4-4AB1-A45A-1DCAA7243342",
  DEVENGAMIENTOS_COBRANZAS: "D239A762-FDD7-4892-9EAB-C74047D17947",
  COBRADORES: "14CB7671-6AB1-4656-B896-CCE8AD81294D",
  CAJEROS: "EFA1A855-AA6D-4370-A1A5-E4355C2BE84D",
  CONDICIONES_IVA: "E8B0F99B-67F0-41E0-A0B7-86C0EF807411",
  CONCEPTOS: "AD41CDAC-26AE-4C54-BC5B-4072B1CFEE53",
  OTRAS_AFILIACIONES: "106ECE90-DAB8-40FF-8999-7270A26DFDBA",
  NACIONALIDADES: "8276DB85-C108-4B1B-974D-1C31A1AC7E4E",
  TIPOS_BENEFICIARIOS: "8377D0D9-F80A-4EB5-B072-2AB5BDAB866C",
  TIPOS_DOCUMENTOS: "1C30F2A6-A24D-4C2C-BF0A-EAA7D055AA5D",
  WEB_PARENTESCOS: "284346A-A72E-4502-8330-6D8B57EEA186",
  MOTIVOS_DE_BAJA: "94AB7B11-76F2-4DF3-82C3-715FB73C9BA9",
  DEPENDENCIAS: "C1F0E7A7-7CFA-43BF-8A72-CB0DC3C883EF",
  CUOTAS: "02BA3045-31C5-4D87-A591-8360FAA1081D",
  AFILIACIONES: "A081B2BE-4F63-430E-BDEB-B91EEC58B3D9",
  MESA_ENTRADA: "6F63C560-CED6-4189-BFB7-10001A69ACBB",
  PRESTACIONES: "E9772B01-4AE4-4792-BF04-0D75BB355247",
  EMAIL: "39979057-CF04-4540-A0A3-3B20BC19C7DC",
  APLICACION_MOVIL: "2B1E29C9-8754-4C26-A0FE-CAD1C43A66F5",
  PRESTADORES: "5B971B7B-D55B-4C5A-9CA3-BE40A8487959",
  ADMINISTRACION_SISTEMA: "5508849B-FD0A-4CE8-9785-A2DEEBE9E79E",
  CONDICIONES_DE_IVA: "E8B0F99B-67F0-41E0-A0B7-86C0EF807411",
  GESTION_AGENTES_CUENTA: "69E7E01A-40A4-4BA7-9920-A7A372A75BE4",
  EDIT_AGENTE_CUENTA: "57AFB4CC-E2CF-47E9-A829-B71D3C444383",
  VENCIMIENTO_CUIT: "762C7D78-0B6F-44C4-9610-7410A57F6A2D",
  TABLA_AFILIADOS: "D2E97D7E-7B48-4A5E-9C42-476697A1A014",
  CONFIG_BAJAS_AUTOMATICAS: "9FB3FB9E-B60D-494E-A76D-7F519629FF2D",
  FACTURACION_OS: "39EB062A-3131-4F3B-ACF6-5E0C81A18104",
  TIPOS_BENEFICIARIOS: "8377D0D9-F80A-4EB5-B072-2AB5BDAB866C",
  ESTADOS_CIVILES: "C3CB33BB-28F0-4BF2-801B-3926C7973E90",
  DEVENGAMIENTOS_CUOTAS: "DE1EE36D-375B-44F2-8C73-380011C15D69",
  CUPONES_PAGO: "4886DCAB-25E6-4401-9DE3-58E1FE727AB5",
  CATEGORIZACION: "0DE0868C-2E3D-47F3-A0E7-9E0BA0B3600D",
  DEVENGAMIENTOS_POR_CONVENIO: "A740D3F4-0A11-414A-8026-FA1F1AC74E07",
  TOKEN: "7DB44F4B-261C-4F8F-AC8D-C65FFD06C831",
  TIPOS_AFILIACION: "FA507EF4-E331-4EF1-840F-889024A99B10",
  REGLAS_COMERCIALES: "486424C8-A557-4A32-A960-BE4D06D5C124",
  CONSULTA_DDJJ_APORTES: "A4B803EC-2291-4FB9-A923-29E0EDA7DDE5",
  TIPOS_DE_COMPROBANTES: "84FD43C3-4658-4CC5-BD3B-D367BD58FA16",
  SOLICITUDES_DE_AFILIACION: "B580B051-1D11-4A34-8395-7D92D4F27A1F",
  TIPO_DE_SOLICITUD: "7EA3AABB-E1BD-449C-A58E-FCD47447A117",
  VENDEDORES: "5E011575-B86E-427D-9C82-7666B24040E0",
  PADRON_MENSUAL_SSS: "7485B5F2-21E1-46FB-9ED3-EDA8503B7471",
  RNOS_OBRAS_SOCIALES: "B4CE28E7-7D14-4EF1-9236-2FCCE46493B6",
  CONFIGURACION_DDJJ_APORTES: "F3F38814-C3BF-4DE2-A3AB-9764A6C6AB4C",
  EMPRESAS: "A1B78338-5164-4858-A04F-A1EAD813B094",
  TOPES_REMUNERACIONES: "6272837A-675F-4DE2-AD83-8F53C6EA307C",
  DESCUENTOS_APORTES_CONTRIBUCIONES: "E7F40ADD-7593-4DE9-8BC4-FBF5E72DB502",
  CONCEPTOS_AFIP: "A299C2E9-8FF6-4AD4-A2C8-8EDFBCA1F595",
  CONFIG_DDJJ_INGRESOS_TOPES_CUOTAS: "BC91D4EF-4048-4AEC-A301-F23A2B7CC427",
  VALORES_CAPITA_ANSES_JUBILACION: "D20B7C0E-D118-474B-A2A3-A093DEEFAF40",
  TABLAS_REMUNERACIONES_MINIMAS: "EDD42A46-0B5E-4E94-8FED-20114EC22001",
  VALORES_CAPITA_ANSES_DESEMPLEO: "F6FF6EFE-4253-4D25-AE62-27D429C1A672",
  TABLA_SICOSS: "42EF551D-46A6-4AFB-935F-A140F13FD586",
  GERENCIADORAS_NIVELES_ATENCION: "DCF667D3-8381-4443-87D6-FE35BBA65062",
  PLANES: "34893728-140E-4A8F-B8BB-F47A6EEDD3DB",
  CONFIGURACION_PLANES: "3C9CC17C-B583-4D01-BAA4-EB81E62FC8E6",
  PROCESOS_COBRANZA: "DF3A137D-24C1-4EAE-BD46-E6D54783C747",
  PROCESAR_COBRANZAS: "8FB4D8E3-9C24-4A53-A51A-CB823C70B9AF",
  CONCEPTOS_A_DEVENGAR: "6C7F09DF-FC08-49BE-8758-9C004B29F37B",
  CONFIGURACION_ADM_SISTEMA: "2A48CDF3-5EA6-4DE7-87C2-D4D6FDF70143",
  PANTALLA_LOGIN_ADMINISTRACION: "D1641BEA-D8F8-40D0-91D8-7B8026A7CC88",
  LOG_SINCRONIZACION_GECLISA: "9DF5A9E3-A313-45AF-B91B-677BEE0C4D63",
  CONFIGURACION_PERMISOS: "34458382-8E06-4DB4-B1C0-69A49FF66A21",
  PROCESOS_DEVENGAMIENTOS: "EF48BE94-1EAB-442F-9D1C-4995BC28734E",
  DEVENGAMIENTOS_POR_GRUPO: "D6161E2E-E60A-403A-A065-B64E6364E779",
  DEVENGAMIENTOS_DESDE_ARCHIVOS: "C14B572B-9A4E-4CFE-AAA0-F6B8382BDB34",
  DEVENGAMIENTO_COSEGUROS: "0A9EE71E-FE94-4D39-9BE4-EE671F3FEF23",
  GENERACION_REGLAS_COMERCIALES: "B0463B2C-AC5F-4D00-B836-56C507DAF3A6",
  DEVENG_COSEGUROS_WEB: "8B4B9393-8F40-4479-8CB5-52D5EE6E24A5",
  IMPORTES_DEVENGAR: "71A0DEBC-AE45-49A3-BB08-90C0CD6FB9DE",
  ADICIONALES_EXTRA: "12C44437-A648-4A08-AFA1-344DAAA7C4BE",
  DEVENG_ADIC_EXTRA: "A509B310-0A56-4ABD-97C4-69D2A0E338F4",
  PROCESOS_DE_IMPORTACION: "880CA8D0-25D6-42D8-8C1F-BA151580EE56",
  PROCESOS_DE_ACTUALIZACION: "2C228B25-36A0-4A74-9B68-D920192F58F1",
  APORTES_ESPERADOS: "E6A98CB2-39D7-40F4-A8D3-57205869B700",
  MOVIMIENTOS_MASIVOS: "C628ADF1-3CD5-405F-8B30-3F1231CCB34E",
  ACTUALIZACION_ADICIONALES_EXTRA: "98749A36-BC1F-49C4-AAB4-1C2907D5BCBD",
  NUEVA_ACTUALIZACION_ADICIONALES_EXTRA: "8CE1DBD2-17AB-4506-AE6B-5D4A74DD86F2",
  ACTUALIZACION_IMPORTES_FIJOS_DEVENGAR: "A2D493E3-0A63-4F8B-88A9-0FD17C0CB9A7",
  CONFIGURAR_PROCESO_MOV_MASIVO: "CCDF987B-FE28-4A26-8DFC-C8CA07ABBEB1",
  NUEVO_PROC_MOV_MASIVO: "013ED791-34E1-4FCD-A296-DD93EC085089",
  PROVEEDORES: "25379FA3-AD0C-4EFB-92D8-1F042165B190",
  ORDENES_DE_PAGO: "8EAF7172-3F6C-4601-AE96-816BCCF14BF1",
  CONFIGURACION_ORDENES_DE_PAGO: "3A9C838A-35A5-4DE7-ABF7-62E0772E6FC5",
  FORMATOS_EMAIL_ORDENES_DE_PAGO: "394323C9-BAC5-4DF0-AAD6-D65AB5F0BB47",
  FORMATOS_IMPRESION_ORDENES_DE_PAGO: "C78439F6-6713-41A2-BBDF-50E7F1CC6273",
  CONVENIOS: "065E8D02-6CC8-4A22-B37F-BCDFB38CE28F",
  PROCESOS_DESREGULACION: "DCA7D8CF-16EA-4C1B-A52D-D392442F67C9",
  NUEVO_PROCESO_DESREGULACION: "B4A668A8-4E87-4A3B-86A7-99D9FC84D38D",
  CONFIG_CONCEPTO_DESREGULACION: "84BD6385-260E-4C0F-ADB0-0EC517973AD9",
  CONFIG_COMISIONES_CONCEPTOS: "CA6FC549-583B-446D-9FE7-C51DE6C6CF1E",
  GRUPOS_DE_DEVENGAMIENTO: "462CF5B8-EFC7-4D92-ADFC-B9C1BBB28632",
  TIPOS_COMPROBANTES_COBRO: "F4FF318F-79EC-4B41-9B2F-D41B65D145CA",
  CONSULTA_BAJAS_AUTOMATICAS: "71B6D1F1-713E-4E95-8ACE-7BB018B5593B",
  CARGA_APORTES: "FD5DB418-5841-414C-9B35-F5BA0C547D0E",
  CONSULTAR_BAJAS_AUTOMATICAS: "71B6D1F1-713E-4E95-8ACE-7BB018B5593B",
  NUEVO_PROCESO_DISTRIBUCION_APORTES_WEB:
    "D4A19C90-8A05-4F09-A7CD-AD5ABB5E6F6D",
  COBERTURAS_ESPECIALES: "D2DE87AE-3690-448C-8D8A-6FDB123BDDE4",
  TIPOS_COBERTURAS_ESPECIALES: "3765110C-F3BB-40B2-A302-B1DC8626A75F",
  COSEGURO_TIPO_COBERTURA_ESPECIAL: "AFC15FF6-3526-406C-963C-599EC9503203",
  TIPOS_PRESTADORES: "EAC75534-777A-4DCA-8477-746F8247DB65",
  CODIGOS_PRESCRIBIBLES: "91B140A9-9699-4298-8C1E-572DE73BC962",
  ESPECIALIDADES: "26EC35D5-D20D-4054-982D-E137D4C36E25",
  CONFIGURACION_PRESTADORES: "708D8678-E23D-4610-A807-E5B6C1F76A8A",
  DDJJ_INGRESOS_TOPES_CUOTAS: "7E216CE9-1583-47C4-B61E-E434777E5A17",
  CONSULTA_PROCESOS_MASIVOS_OP: "FFAD3492-EEDF-49B3-9C6E-B8C53BFAAAEC",
  CONFIGURACION_CONVENIOS: "1C3E4847-2D45-4496-9AE1-7D97E2AA8A85",
  RELACION_CONVENIO_EMPRESA: "37DB33A4-3D8E-467E-8274-C85AC4BDC8D0",
  FORMATOS_EXPORTACION_TRANSFERENCIAS: "9E14163F-7EDC-498C-944B-13E99204992A",
  TOPES_COBERTURAS_ESPECIALES: "ADC14195-2F7B-4AB5-94BD-F37236F54EE7",
  IMPORTES_FIJOS_POR_GRUPO_FLIAR: "C4048282-1D16-462E-8CE0-321CA43C1859",
  ANALYTICS: "833A7BF3-0B91-45FC-A109-330C04925020",
  CONFIGURACION_TABLEROS_ANALYTICS: "A5BEE39A-EB38-48EE-9DFA-C83155EA44CC",
  ANALYTICS_AFILIACIONES: "F3D97035-E78C-43A1-A981-25E3797C3777",
  ANALYTICS_PRESTACIONES: "DDDFD614-26CC-48ED-83CC-64A3D0D2AAAC",
  ANALYTICS_INGRESOS: "918F6084-BC94-4B68-BC6F-D9265ACBD672",
  ANALYTICS_AFILIACIONES_PRESTACIONES_INGRESOS: "003F34D8-039A-42C0-B6B3-6E1337277CE6",
  ANALYTICS_OTROS_TABLEROS: "CE97C5F6-F7DA-4A63-AD68-AF1EF1507A35",
  CONSULTA_TABLA_FAC: "41CB91F1-A524-422C-886F-8503D79664AD",
  COTIZADOR: "FDB7A194-50A2-4172-B4A1-0519BAC700D8",
  CONFIGURACION_COTIZADOR: "CFD6ADD2-A253-4C52-A2A7-16D1EDEBC18D",
  FORMATOS_IMPRESION_COTIZADOR: "7C8CA1A7-3D0D-4E21-BAEE-441465A1B607",
  FORMATOS_EMAIL_COTIZADOR: "D8F65F1A-89FF-47D8-848A-A5A175883912",
  GRUPOS_ENTIDADES_EFECTORAS: "C9CFACF0-4725-4BFC-A16B-4A5E5C0DA111",
  MONEDAS: "37DC32A6-2125-470A-9330-2C540B3658CD",
  COTIZACIONES_MONEDAS: "90ACA4DA-F4E1-4688-A6B9-86BE157D783A",
  CUENTA_CORRIENTE_PROVEEDORES: "E8208E6C-0011-44DB-9238-17BE6E608170",
  CANTIDAD_REGISTROS: "350AAD62-B7E8-413E-AEB5-9ECC2B89AB09"
});

export default webSiteOptions;
