import axios from "@/axios/axios-api";

const actions = {
  async getLotesDeuda(context, payload) {
    const response = await axios.post("api/lotesDeuda/getByFilters", payload);
    return response.data.data;
  },
  async deleteLoteDeuda(context, payload) {
    const response = await axios.delete(
      `api/lotesDeuda/${payload.loteDeudaId}`
    );
    return response;
  },
  async getComprobantesLotesDeuda(context, payload) {
    const response = await axios.post(
      "api/lotesDeuda/getByVistasFilters/comprobantes",
      payload
    );
    return response.data.data;
  },
  async getComprobanteById(context, payload) {
    const response = await axios.get(
      `api/lotesDeuda/comprobante/${payload.compId}`
    );
    return response.data.data;
  },
  async getEntidadesFacturantes() {
    const response = await axios.get("api/taxonomy/EntidadesFacturantes");
    return response.data;
  },
  async getTiposDeComprobantes(context, payload) {
    const response = await axios.get(
      `api/taxonomy/TiposDeComprobantes/${payload}`
    );
    return response.data;
  },
  async getEntidadesFinancieras() {
    const response = await axios.get("api/taxonomy/entidadesFinancieras");
    return response.data;
  },
  async getFormasDePago() {
    const response = await axios.get("api/taxonomy/formasDePago");
    return response.data;
  },

  async postLotesDeuda(context, payload) {
    const response = await axios.post("api/lotesDeuda", payload);
    return response;
  },
  async deleteComprobanteFromLote(context, payload) {
    const response = await axios.delete(
      `api/lotesDeuda/deleteComprobante/${payload.loteDeudaId}/comprobante?compId=${payload.compId}`
    );
    return response;
  },
  async getLoteDeudaById(context, payload) {
    const response = await axios.get(`api/lotesDeuda/${payload}`);
    return response.data.data;
  },
  async postSaveView(context, payload) {
    const response = await axios.post("api/vistasPantallas", payload);
    return response;
  },
  async getViews(context, payload) {
    const response = await axios.get(
      `api/vistasPantallas/OptionCode?view=${payload}`
    );
    return response.data.data;
  },
  async deleteViewById(context, payload) {
    const response = await axios.delete(
      `api/vistasPantallas/${payload.viewId}`
    );
    return response;
  },
  async getUsuarios() {
    const response = await axios.get("api/taxonomy/usuarios");
    return response.data;
  },
  async getComprobantesEnvio(context, payload) {
    const response = await axios.get(
      `api/lotesDeuda/comprobantesEnvio/${payload}`
    );
    return response.data.data;
  },
  async getEnvioConfiguracion(context, payload) {
    const response = await axios.post(
      "api/lotesDeuda/envioConfiguracion",
      payload
    );
    return response.data.data;
  },
  async sendSiroApi(context, payload) {
    const response = await axios.post("api/enviosConfiguracion/siro", payload);
    return response;
  },
  async generarArchivoCredicoopCSV(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/archivoCSV",
      payload
    );
    return response.data.data;
  },
  async generarVISAMOV(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/visa-mov",
      payload
    );
    return response.data;
  },
  async generarVISADebito(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/visa-debito",
      payload
    );
    return response.data;
  },
  async generarVISACredito(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/visa-credito",
      payload
    );
    return response.data;
  },
  async generarNaranjaCredito(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/naranja-credito",
      payload
    );
    return response;
  },
  async generarMastercardDebito(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/mastercard-debito",
      payload
    );
    return response;
  },
  async generarPagoMisCuentasTxt(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/pago-mis-cuentas",
      payload
    );
    return response;
  },
  async generarBancoProviniciaPagos(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/bapro-pagos",
      payload
    );
    return response;
  },
  async generarPagosLink(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/pagos-link",

      payload
    );
    return response.data;
  },

  async generarPagosDebitoCredicoop(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/debito-credicoop",

      payload
    );
    return response.data;
  },
  async getNombreArchivo(context, payload) {
    const response = await axios.get(
      `api/enviosConfiguracion/GetNombreArchivo/${payload}`
    );
    return response.data.data;
  },

  async generarGalicia(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/galicia",
      payload
    );
    return response.data;
  },
  async generarMacroLink(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/macro-link",
      payload
    );
    return response.data.data;
  },
  async getEnviosByLoteDeuda(context, payload) {
    const response = await axios.get(`api/lotesDeuda/enviosLote/${payload}`);
    return response.data.data;
  },
  async fetchConceptosADevengarByParams(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/fetchByParams",
      payload
    );
    return response.data.data;
  },
  async getConceptos() {
    const response = await axios.get("api/taxonomy/conceptos");
    return response.data.data;
  },
  async getConceptosAdicionales() {
    const response = await axios.get("api/taxonomy/conceptosAdicionales");
    return response.data;
  },
  async deleteConceptosXUnicaVez(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/deleteConceptosADevengar",
      payload
    );
    return response;
  },
  async deleteConceptoPorPeriodo(context, payload) {
    const response = await axios.delete(
      `api/conceptosADevengar/deleteConceptoADevengarPorPeriodo/${payload}`
    );
    return response;
  },
  async getTitularesByAgecta(context, payload) {
    const response = await axios.get(
      `api/taxonomy/titularesByAgecta/${payload}`
    );
    return response.data.data;
  },
  async saveInteresesACobrar(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/saveConceptosDevengar",
      payload
    );
    return response;
  },
  async saveConceptosDevengarPorPeriodo(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/saveConceptosDevengarPorPeriodo",
      payload
    );
    return response;
  },
  async getProcesosImportacionXUnicaVez() {
    const response = await axios.get(
      "api/conceptosADevengar/procesosImportacionXUnicaVez"
    );
    return response.data.data;
  },
  async anularProcesoImportacionXUnicaVez(context, payload) {
    const response = await axios.get(
      `api/conceptosADevengar/anularProcesoImportacionXUnicaVez/${payload.procesoImportacionId}`
    );
    return response;
  },
  async getFormatoProcesoImportacionXUnicaVez() {
    const response = await axios.get(
      "api/conceptosADevengar/formatoProcesoImportacionXUnicaVez"
    );
    return response.data.data;
  },
  async saveProcesoImportacionXUnicaVez(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/saveProcesoImportacionXUnicaVez",
      payload
    );
    return response;
  },
  async verDetalleProcesoImportacionXUnicaVez(context, payload) {
    const response = await axios.get(
      `api/conceptosADevengar/detalleProcesoImportacionXUnicaVez/${payload}`
    );
    return response.data.data;
  },
  async getProcesosActualizacionXPeriodo(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/procesosActualizacionXPeriodo",
      payload
    );
    return response.data.data;
  },
  async verDetalleProcesoActualizacionXPeriodo(context, payload) {
    const response = await axios.get(
      `api/conceptosADevengar/detalleProcesoActualizacionXPeriodo/${payload}`
    );
    return response.data.data;
  },
  async fetchConceptosADevengarByParamsToProceso(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/fetchConceptosADevengarByParamsToProceso",
      payload
    );
    return response.data.data;
  },
  async completarActualizacionConceptosXPeriodo(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/CompletarActualizacionConceptosXPeriodo",
      payload
    );
    return response.data.data;
  },
  async getFormatoProcesoActualizacionXPeriodo() {
    const response = await axios.get(
      "api/conceptosADevengar/formatoProcesoActualizacionXPeriodo"
    );
    return response.data.data;
  },
  async grabarActualizacionConceptosXPeriodo(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/grabarActualizacionConceptosXPeriodo",
      payload
    );
    return response;
  },
  async anularProcesoAdicionalesXPeriodo(context, payload) {
    const response = await axios.get(
      `api/conceptosADevengar/anularProcesoActualizacionAdiXPeri/${payload.procId}`
    );
    return response;
  },
  async generarDebitoSupervielle(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/debito-supervielle",
      payload
    );
    return response;
  },
  async getNombreArchivoDebitoSupervielle(context, payload) {
    const response = await axios.get(
      `api/enviosConfiguracion/nombre-archivo-debito-supervielle/${payload}`
    );
    return response.data.data;
  },
  async getAdicionalesExtra(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/GetAdicionalesExtra",
      payload
    );
    return response.data.data;
  },
  async getFormasDePagoPideEntFin(context, payload) {
    const response = await axios.get(
      "api/taxonomy/FormasDePagoPideEntFin",
      payload
    );
    return response.data.data;
  },
  async saveAdicionalExtra(context, payload) {
    const response = await axios.post(
      "api/conceptosADevengar/SaveAdicionalExtra",
      payload
    );
    return response;
  },
  async deleteAdicionalExtra(context, payload) {
    const response = await axios.delete(
      `api/conceptosADevengar/DeleteAdicionalExtra/${payload}`
    );
    return response;
  },
  async consultaAumentoAdicionales(context, payload) {
    const response = await axios.post(
      "api/adicionalesExtra/ConsultaAumentoAdicionalesExtra",
      payload
    );
    return response.data.data;
  },
  async anulaAumentoAdicionales(context, payload) {
    const response = await axios.delete(
      `api/adicionalesExtra/AnulaAumentoAdicionalesExtra/${payload.devAdiExtraAumentoId}`
    );
    return response;
  },
  async nuevoProcesoAumentoAdicionales(context, payload) {
    const response = await axios.post(
      "api/adicionalesExtra/AumentoMasivoAdicionalesExtra",
      payload
    );
    return response.data.data;
  },
  async verDetalleAumentoAdiExtra(context, payload) {
    const response = await axios.get(
      `api/adicionalesExtra/VerDetalleActualizacion/${payload.aumentoId}`
    );
    return response.data.data;
  },
  async generarGecrosEstandar(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/gecros-estandar",
      payload
    );
    return response.data.data;
  },
  async getDDJJTopeCuotas(context, payload) {
    const response = await axios.post(
      "api/dDJJTopeCuotas/getDDJJTopeCuotas",
      payload
    );
    return response.data.data;
  },
  async deleteDDJJIngresosTopesCuotas(context, payload) {
    const response = await axios.post(
      "api/dDJJTopeCuotas/deleteDDJJTopeCuotas",
      payload
    );
    return response;
  },
  async importTxtDDJJIngresosTopesCuotas(context, payload) {
    const response = await axios.post(
      "api/dDJJTopeCuotas/importTxtDDJJTopeCuotas",
      payload
    );
    return response;
  },
  async getImportesFijosADevengarByParams(context, payload) {
    const response = await axios.post(
      "api/importesFijosDevengar/ConsultaImportesFijosADevengar",
      payload
    );
    return response.data.data;
  },
  async deleteImportesFijosADevengar(context, payload) {
    const response = await axios.delete(
      `api/importesFijosDevengar/DeleteImportesFijosADevengar/${payload}`
    );
    return response;
  },
  async saveImportesFijosADevengar(context, payload) {
    const response = await axios.post(
      "api/importesFijosDevengar/SaveImportesFijosADevengar",
      payload
    );
    return response;
  },

  //Configuracion de DDJJ de ingresos para topes en cuotas

  async configGetDDJJTopeCuotas() {
    const response = await axios.get(
      "api/DDJJTopeCuotas/ConfigDdjjTopesCuotas"
    );
    return response.data.data;
  },
  async saveConfigDdjjTopesCuotas(context, payload) {
    const response = await axios.post(
      "api/DDJJTopeCuotas/saveConfigDdjjTopesCuotas",
      payload
    );
    return response;
  },
  async deleteConfigDdjjTopesCuotas(context, payload) {
    const response = await axios.delete(
      `api/DDJJTopeCuotas/ConfigDdjjTopesCuotas/${payload.ddjjtopesCuotaConfId}`
    );
    return response;
  },
  async saveConfigConceptosDdjjTopesCuotas(context, payload) {
    const response = await axios.post(
      "api/Conceptos/ConfigConceptosAjusteIva",
      payload
    );
    return response;
  },
  async getConfigConceptosDDJJTopeCuotas() {
    const response = await axios.get("api/Conceptos/ConfigConceptosAjusteIva");
    return response.data.data;
  },
  async getProcesoActualizacionImportesFijos() {
    const response = await axios.get(
      "api/importesFijosDevengar/FetchProcesosActualizacion"
    );
    return response.data.data;
  },
  async saveProcesoActualizacionImportesFijos(context, payload) {
    const response = await axios.post(
      "api/importesFijosDevengar/SaveProcesoActualizacion",
      payload
    );
    return response;
  },
  async getImportesFijosAndUpdateImporte(context, payload) {
    const response = await axios.post(
      "api/importesFijosDevengar/GetImportesFijosAndUpdateImporte",
      payload
    );
    return response.data.data;
  },
  async verDetalleImportesFijos(context, payload) {
    const response = await axios.get(
      `api/importesFijosDevengar/DetalleProcesosActualizacion/${payload}`
    );
    return response.data.data;
  },
  async anulaImporteFijo(context, payload) {
    const response = await axios.delete(
      `api/importesFijosDevengar/AnularProcesoActualizacion/${payload}`
    );
    return response;
  }
};

export default actions;
