import axios from "@/axios/axios-api";
import getAPIUrl from "@/utils/helpers/getAPIUrl";

const actions = {
  async fetchFormatoImpresionCotizadores() {
    const response = await axios.get("api/cotizador/formatoImpresion");
    return response.data.data;
  },
  async getConveniosByFormatoImpresion(context, payload) {
    const response = await axios.get(
      `api/taxonomy/formatoImpresionConvenio/${payload.id}`
    );
    return response.data.data;
  },
  async getConveniosConFormatoImpresion() {
    const response = await axios.get(
      "api/taxonomy/ConveniosConFormatoImpresion"
    );
    return response.data.data;
  },
  async getCotizaciones(context, payload) {
    const response = await axios.post("api/cotizador/cotizaciones", payload);
    return response.data.data;
  },
  async getCategoriaByParams(context, payload) {
    const response = await axios.post(
      "api/cotizador/categoriasByParams",
      payload
    );
    return response.data.data;
  },
  async imprimirCotizacion(context, payload) {
    const token = localStorage.getItem("token");
    const urlAPI = await getAPIUrl();
    return new Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();
      if (process.env.NODE_ENV == "development") {
        xhr.open(
          "POST",
          `${urlAPI}/api/cotizador/imprimir/${payload.id}`,
          true
        );
      } else {
        xhr.open(
          "POST",
          `${
            urlAPI.endsWith("/") ? urlAPI.slice(0, -1) : urlAPI
          }/api/cotizador/imprimir/${payload.id}`,
          true
        );
      }
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Content-type", "application/json;charset=UTF-8");
      xhr.responseType = "arraybuffer";

      let params = {
        id: payload.id,
        tipoImpresion: payload.tipoImpresion
      };
      xhr.send(JSON.stringify(params));

      // en Firefox no funciona con este tratamiento el preview del navegador para imprimir un archivo
      // por lo que en la parte del "else" lo descarga como pdf directamente
      // VER TRATAMIENTO PARA QUE SE PUEDA IMPRIMIR DIRECTAMENTE
      if (payload.browser !== "F") {
        xhr.onload = e => {
          if (e.target.status === 200) {
            let blob = new Blob([e.target.response], {
              type: "application/pdf"
            });
            let objFra = document.querySelector("#imprimir");
            if (objFra === null) {
              objFra = document.createElement("iframe");
              objFra.setAttribute("id", "imprimir");
            } else {
              objFra = document.getElementById("imprimir");
            }
            objFra.style.visibility = "hidden";
            objFra.src = window.URL.createObjectURL(blob);
            document.body.appendChild(objFra);
            objFra.contentWindow.focus();
            objFra.contentWindow.print();
          } else {
            context.commit(
              "user/setAlertMessage",
              {
                type: "warning",
                message: "No se pudo recuperar el archivo."
              },
              { root: true }
            );
          }
        };
      } else {
        xhr.onload = e => {
          if (e.target.status === 200) {
            resolve(e.target.status);
            const fileName = payload.fileName;
            let blob = new Blob([e.target.response], {
              type: "application/pdf"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", fileName);
            link.download = fileName;
            link.click();
          } else {
            context.commit(
              "user/setAlertMessage",
              {
                type: "warning",
                message: "No se pudo recuperar el archivo"
              },
              { root: true }
            );
            reject(e.target.status);
          }
        };
      }
    });
  },
  async valorizarIntegranteCotizador(context, payload) {
    const response = await axios.post(
      "api/cotizador/valorizarIntegrante",
      payload
    );
    return response;
  },
  async recalcularCotizacion(context, payload) {
    const response = await axios.post(
      "api/cotizador/calcularCotizacion",
      payload
    );
    return response;
  },
  async saveCotizacion(context, payload) {
    const response = await axios.post("api/cotizador/SaveCotizacion", payload);
    return response;
  },
  async sendEmailIndividualCotizacion(context, payload) {
    const response = await axios.post(
      "api/cotizador/SendEmailIndividualCotizacion",
      payload
    );
    return response;
  },
  async validateEmailCoti(context, payload) {
    const response = await axios.get(
      `api/cotizador/ValidateEnvioEmailCoti/${payload}`
    );
    return response;
  },
  async getVendedoresAfiXUsu() {
    const response = await axios.get("api/taxonomy/vendedoresAfiXUsu");
    return response.data.data;
  },
  async valorizarReglaComercial(context, payload) {
    const response = await axios.post(
      `api/cotizador/valorizarReglaComercial`,
      payload
    );
    return response.data.data;
  },
  async getConveniosSoliAfiByVigencia(context, payload) {
    const response = await axios.get(
      `api/taxonomy/ConveniosSoliAfiByVigencia/${payload}`
    );
    return response.data.data;
  },
  async getReglasComercialesCotizador(context, payload) {
    const response = await axios.get(
      `api/cotizador/reglasComercialesCotizador/${payload}`
    );
    return response.data.data;
  },
  async getAllParentescos() {
    const response = await axios.get("api/taxonomy/parentescos");
    return response.data;
  },
  async getCategoriasAfiliacion() {
    const response = await axios.get("api/taxonomy/categoriasAfiliacion");
    return response.data.data;
  },
  async deleteCotizacion(context, payload) {
    const response = await axios.delete(
      `api/cotizador/deleteCotizacion/${payload}`
    );
    return response;
  },
  async fetchFormatosEmail(context, payload) {
    const response = await axios.get(
      `api/formatosEmail/multipleformatos?codigo1=${payload}`
    );
    return response.data;
  },
  async saveEmail(context, payload) {
    const response = await axios.post("api/formatosEmail", payload);
    return response;
  },
  async getFormatoEmail(context, payload) {
    const response = await axios.get(
      `api/formatosEmail/GetFormatoByCodigo?codigo=${payload.codigo}&id=${payload.id}`
    );
    return response.data;
  },
  async deleteFormatoEmail(context, payload) {
    const response = await axios.delete(`api/formatosEmail/${payload}`);
    return response;
  }
};
export default actions;
