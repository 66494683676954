import axios from "@/axios/axios-api";
import getPayload from "@/utils/helpers/getPayload";

const actions = {
  async getSessionCobroMasivo() {
    const response = await axios.get("api/procesosCobranza/session");
    return response.data.data;
  },
  async getComprobantesDeudasByFilters(context, payload) {
    const response = await axios.post(
      "api/procesosCobranza/comprobantesDeuda",
      payload
    );
    return response.data.data;
  },
  async validateComprobantes(context, payload) {
    const response = await axios.get(
      `api/procesosCobranza/comprobantes-proceso-envio/excel?paramsDto=${payload}`
    );
    return response.data.data;
  },
  async getCajasXUsuario() {
    const response = await axios.get("api/taxonomy/cajasXUsuario");
    return response.data.data;
  },
  async generarComprobantesDeudaCobro(context, payload) {
    const response = await axios.post(
      `api/procesosCobranza/generarComprobantesDeudaCobro/${payload.sessionId}?esProceso=${payload.esProceso}`,
      payload.params
    );
    return response.data;
  },
  async deleteCompRegistro(context, payload) {
    const response = await axios.delete(
      `api/procesosCobranza/registroComprobante/${payload.compId}/session?sessionId=${payload.sessionId}`
    );
    return response;
  },
  async deleteAllCompsRegistro(context, payload) {
    const response = await axios.delete(
      `api/procesosCobranza/registroComprobante/session?sessionId=${payload.sessionId}`
    );
    return response;
  },
  async generarComprobantePago(context, payload) {
    const response = await axios.post(
      `api/procesosCobranza/registroComprobante/${payload.sessionId}`
    );
    return response;
  },
  async postComprobantes(context, payload){
    const response = await axios.post("api/importacion/importacion-comprobantes", payload);

    return response.data;
  },
  async getProcesosCobranza(){
    const response = await axios.get("api/procesosCobranza/procesos-cobranza");
    return response.data.data;
  },
  async importRows(context, params) {
    let bodyParams = getPayload(params.bodyParams);
    const url = "api/importacion/importacion-autorizaciones";
   //, {timeout: 60 * 60 * 1000}
    const dataResponse = await axios.post(`${url}?`, bodyParams);
    let result = {};
     result = {
       ok: dataResponse.data.ok,
       descripcion: dataResponse.data.descripcion,
       errores: dataResponse.data.errores,
       nroproceso:dataResponse.data.nroproceso
     };
      
    return result;
   },
   async errorRows(context, nroId)
   {
      return new Promise((resolve, reject) => {
       axios
         .get(`api/importacion/report-error/${nroId}`, {
                   responseType: "blob" // 1. First set the responseType object format to blob:
         })
         .then(
           res => {
             //resolve(res)
             let blob = new Blob([res.data], {
               type: "application/vnd.ms-excel"
             }); // 2. Get the blob setting file type in the response object returned by the request. Here is excel as an example.
             let url = window.URL.createObjectURL(blob); // 3. Create a temporary url pointing to the blob object
             // 4. After creating the url, you can simulate a series of operations on this file object, for example: preview, download
             let link = document.createElement("a");
             link.href = url;
             //link.style = "visibility:hidden";
             link.download = `ErroresImportacion_nroProceso${nroId}.csv`;
             link.click();
             // 5. Release this temporary object url
             window.URL.revokeObjectURL(url);
           },
           err => {
             resolve(err.response);
           }
         )
         .catch(error => {
           reject(error);
         });
     });
   },
   async getDeudaSIROAPI(context, payload){
    const response = await axios.post(`api/procesosCobranza/proceso-cobranza-siro-api?entFinId=${payload.entFinId}&procesoCobranzaConfigId=${payload.procesoCobranzaConfigId}`, payload);
    return response.data.data;
  },

  async postComprobantesMacro(context, payload){
    const response = await axios.post(`api/procesosCobranza/proceso-cobranza-macro-link?entFinId=${payload.entFinId}&procesoCobranzaConfigId=${payload.procesoCobranzaConfigId}`, payload.data);
    return response.data.data;
  },
  async postComprobantesDebitoSupervielle(context, payload){
    const response = await axios.post(`api/procesosCobranza/proceso-cobranza-debito-supervielle?fechaCobro=${payload.fechaCobro}&entFinId=${payload.entFinId}&procesoCobranzaConfigId=${payload.procesoCobranzaConfigId}`, payload.data);
    return response.data.data;
  },
  async postComprobantesCredicoop(context, payload){
    const response = await axios.post(`api/procesosCobranza/proceso-cobranza-credicoop?fechaCobro=${payload.fechaCobro}&entFinId=${payload.entFinId}&procesoCobranzaConfigId=${payload.procesoCobranzaConfigId}`, payload.data);
    return response.data.data;
  }
};

export default actions;
