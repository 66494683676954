import axios from "@/axios/axios-api";

const actions = {
  async getEstadosActas() {
    const response = await axios.get("api/estadosActas");
    return response.data.data;
  },

  async getEstadosActasById(context, payload) {
    const response = await axios.get(`api/estadosActas/${payload}`);
    return response.data.data;
  },

  async getTiposInteresDeudaEmpresa() {
    const response = await axios.get("api/tiposInteresDeudaEmpresa");
    return response.data.data;
  },

  async getTiposInteresDeudaEmpresaById(context, tipoInteresId) {
    const response = await axios.get(
      `api/tiposInteresDeudaEmpresa/${tipoInteresId}`
    );
    return response.data.data;
  },

  async fetchEstadosActa(context) {
    const estadosActa = await axios.get("api/estadosActas/estados");
    return estadosActa.data.data;
  },

  async fetchEstadosActaDestino(context, ultimoEstadoId) {
    const estadosActa = await axios.get(
      `api/estadosActas/origenDestino/${ultimoEstadoId}`
    );
    return estadosActa.data.data;
  },

  async fetchUsuariosByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const usuarios = await axios.get(
        `api/estadosActas/autocomplete?input=${payload.input}`
      );
      return usuarios.data.data;
    }
  },

  async fetchServidores() {
    const response = await axios.get("api/taxonomy/servidores");
    return response.data;
  },

  async getConfiguracionFormasDePago() {
    const response = await axios.get("api/formasPago");
    return response.data.data;
  },

  async getCuentaBancarias() {
    const response = await axios.get(`api/taxonomy/cuentasBancarias`);
    return response.data;
  },

  async getTipoMovBancarios() {
    const response = await axios.get("api/taxonomy/tiposMovBancarios");
    return response.data;
  },

  async getConfiguracionFormasDePagoById(context, payload) {
    const response = await axios.get(`api/formasPago/formaPago/${payload}`);
    return response.data.data;
  },

  async postConfiguracionFormasDePago(context, payload) {
    const response = await axios.post("api/formasPago", payload);
    return response;
  },

  async deleteConfiguracionFormasDePago(context, payload) {
    const response = await axios.delete(`api/formasPago/${payload.fPagoId}`);
    return response;
  },

  async fetchVariables(context, payload) {
    const response = await axios.get(
      `api/formatosEmail/variables?codigo=${payload}`
    );
    return response.data;
  },

  async fetchFormatosEmail(context, payload) {
    const response = await axios.get(
      `api/formatosEmail/multipleformatos?codigo1=${payload}`
    );
    return response.data;
  },

  async fetchFormatosPdf(context, payload) {
    const response = await axios.get(
      `api/formatosPdf/multipleformatos?codigo=${payload}`
    );
    return response.data;
  },
  //action reglas comerciales
  async getReglasComerciales() {
    const response = await axios.get("api/reglasComerciales");
    return response.data.data;
  },
  async getReglasComercialesProc() {
    const response = await axios.get(
      "api/reglasComerciales/reglasComercialesProc"
    );
    return response.data.data;
  },

  async getLogsCambiosByRegla(context, payload) {
    const response = await axios.get(
      `api/reglasComerciales/logsCambiosByRegla/${payload}`
    );
    return response.data.data;
  },
  async getLogsModificacionesByRegla(context, payload) {
    const response = await axios.get(
      `api/reglasComerciales/logsModificacionesByRegla/${payload}`
    );
    return response.data.data;
  },
  async saveReglaComercial(context, payload) {
    const response = await axios.post("api/reglasComerciales", payload);
    return response;
  },

  async deleteReglaComercial(context, payload) {
    const response = await axios.delete(
      `api/reglasComerciales/${payload.rgId}`
    );
    return response;
  },

  async fetchTiposRegistrosAFIPEnum() {
    const response = await axios.get("api/estadosActas/tiposRegistrosAFIP");
    return response.data.data;
  },

  async fetchEnvioConfiguracion() {
    const response = await axios.get("api/enviosConfiguracion");
    return response.data.data;
  },

  async putTiposInteresDeudaEmpresa(context, payload) {
    const {
      tipoInteresId,
      porcentajeInteres,
      tipo,
      vigenciaDesde,
      vigenciaHasta
    } = payload;
    const response = await axios.put("api/tiposInteresDeudaEmpresa", {
      tipoInteresId,
      porcentajeInteres,
      tipo,
      vigenciaDesde,
      vigenciaHasta
    });
    return response;
  },

  async postEstadosActas(context, payload) {
    const {
      estadoActaId,
      nombre,
      descripcion,
      esInicial,
      tipoRegistroAfip,
      estadosDestinoIds,
      usuariosIds
    } = payload;
    const response = await axios.post("api/estadosActas", {
      estadoActaId,
      nombre,
      descripcion,
      esInicial,
      tipoRegistroAfip,
      estadosDestinoIds,
      usuariosIds
    });
    return response;
  },

  async postTiposInteresDeudaEmpresa(context, payload) {
    const {
      tipoInteresId,
      porcentajeInteres,
      tipo,
      vigenciaDesde,
      vigenciaHasta
    } = payload;
    const response = await axios.post("api/tiposInteresDeudaEmpresa", {
      tipoInteresId,
      porcentajeInteres,
      tipo,
      vigenciaDesde,
      vigenciaHasta
    });
    return response;
  },

  async savePdf(context, payload) {
    const response = await axios.post("api/formatosPdf", payload);
    return response;
  },

  async saveEmail(context, payload) {
    const response = await axios.post("api/formatosEmail", payload);
    return response;
  },

  async deleteTiposInteresDeudaEmpresa(context, payload) {
    const response = await axios.delete(
      `api/tiposInteresDeudaEmpresa/${payload.tipoInteresId}`
    );
    return response;
  },

  async deleteFormatoEmail(context, payload) {
    const response = await axios.delete(`api/formatosEmail/${payload}`);
    return response;
  },

  async deleteFormatoPdf(context, payload) {
    const response = await axios.delete(`api/formatosPdf/${payload}`);
    return response;
  },

  async deleteEstadosActas(context, payload) {
    const response = await axios.delete(
      `api/estadosActas/${payload.estadoActaId}`
    );
    return response;
  },
  async getProcesosEnvio() {
    const response = await axios.get("api/taxonomy/procesosEnvio");
    return response.data;
  },
  async getProcesosDetalleById(context, payload) {
    const response = await axios.get(
      `api/enviosConfiguracion/procesoDetalle/${payload}`
    );
    return response.data.data;
  },
  async postEnvioConfiguracion(context, payload) {
    const response = await axios.post("api/enviosConfiguracion", payload);
    return response;
  },
  async deleteEnviosConfiguracion(context, payload) {
    const response = await axios.delete(
      `api/enviosConfiguracion/${payload.envioConfiguracionId}`
    );
    return response;
  },
  async getEnviosConfiguracionById(context, payload) {
    const response = await axios.get(`api/enviosConfiguracion/${payload}`);
    return response.data.data;
  },
  async getVariablesEnvios() {
    const response = await axios.get("api/taxonomy/variablesEnvios");
    return response.data;
  },

  async getConfiguracionEntidadesFinancierasById(context, payload) {
    const response = await axios.get(
      `api/entidadesFinancieras/entidadFinanciera/${payload}`
    );
    return response.data.data;
  },
  async getEntidadesFinancieras() {
    const response = await axios.get("api/entidadesFinancieras");
    return response.data.data;
  },
  async postConfiguracionEntidadesFinancieras(context, payload) {
    const response = await axios.post("api/entidadesFinancieras", payload);
    return response;
  },
  async deleteConfiguracionEntidadesFinancieras(context, payload) {
    const response = await axios.delete(
      `api/entidadesFinancieras/${payload.entFinId}`
    );
    return response;
  },

  async getCajeros() {
    const response = await axios.get(`api/cajeros`);
    return response.data;
  },

  async getCajeroById(context, payload) {
    const response = await axios.get(`api/cajeros/CajaById/${payload}`);
    return response.data.data;
  },

  async getUsuariosCajeros() {
    const response = await axios.get(`api/cajeros/usuarios`);
    return response.data;
  },

  async postCajero(context, payload) {
    const response = await axios.post("api/cajeros", payload);
    return response;
  },
  async getCobradores() {
    const response = await axios.get("api/cobradores");
    return response.data.data;
  },
  async deleteCobradores(context, payload) {
    const response = await axios.delete(`api/cobradores/${payload.venId}`);
    return response;
  },
  async getTiposIvaCobradores() {
    const response = await axios.get("api/taxonomy/tiposIvaCobradores");
    return response.data;
  },
  async postCobradores(context, payload) {
    const response = await axios.post("api/cobradores", payload);
    return response;
  },
  //VencimientoCuit
  async getVencimientosDDJJ() {
    const response = await axios.get("api/DDJJVencimientoCuit");
    return response.data.data;
  },

  async saveVencimientoDDJJ(context, payload) {
    const response = await axios.post("api/DDJJVencimientoCuit", payload);
    return response;
  },

  async deleteDdjjVencimientoCuit(context, payload) {
    const response = await axios.delete(
      `api/dDJJVencimientoCuit/deleteDdjjVencimientoCuit/${payload.ddjjVencimientoId}`
    );
    return response;
  },

  // Valores cápita anses jubilados
  async getValoresCapitaANSES(context, payload) {
    const request = payload ? `?fechaVigencia=${payload}` : "";
    const response = await axios.get(
      "api/DDJJAportes/valores-capita-anses" + request
    );
    return response.data.data;
  },
  async saveValorCapitaANSES(context, payload) {
    const response = await axios.post(
      "api/DDJJAportes/valores-capita-anses",
      payload
    );
    return response;
  },
  async deleteValorCapitaANSES(context, payload) {
    const response = await axios.delete(
      `api/DDJJAportes/valores-capita-anses/${payload.montoCapitaId}`
    );
    return response;
  },
  // Valores cápita anses desempleo
  async getValoresCapitaANSESDesempleo(context, payload) {
    const request = payload ? `?fechaVigencia=${payload}` : "";
    const response = await axios.get(
      "api/DDJJAportes/valores-capita-anses-desempleo" + request
    );
    return response.data.data;
  },
  async saveValorCapitaANSESDesempleo(context, payload) {
    const response = await axios.post(
      "api/DDJJAportes/valores-capita-anses-desempleo",
      payload
    );
    return response;
  },
  async deleteValorCapitaANSESDesempleo(context, payload) {
    const response = await axios.delete(
      `api/DDJJAportes/valores-capita-anses-desempleo/${payload.montoId}`
    );
    return response;
  },

  async deleteCajero(context, payload) {
    const response = await axios.delete(`api/cajeros/${payload.idCaja}`);
    return response;
  },

  async getCondicionesIva() {
    const response = await axios.get("api/condicionesIVA");
    return response.data.data;
  },
  async getCondicionIvaById(context, payload) {
    const response = await axios.get(
      `api/condicionesIVA/condicionIVA/${payload}`
    );
    return response.data.data;
  },
  async saveCondicionIva(context, payload) {
    const response = await axios.post("api/condicionesIVA", payload);
    return response;
  },
  async deleteCondicionIva(context, payload) {
    const response = await axios.delete(`api/condicionesIVA/${payload.ivaId}`);
    return response;
  },

  async getConceptos() {
    const response = await axios.get("api/conceptos");
    return response.data.data;
  },
  async getConceptoById(context, payload) {
    const response = await axios.get(`api/conceptos/concepto/${payload}`);
    return response.data.data;
  },
  async postConcepto(context, payload) {
    const response = await axios.post("api/conceptos", payload);
    return response;
  },
  async deleteConcepto(context, payload) {
    const response = await axios.delete(`api/conceptos/${payload.concId}`);
    return response;
  },
  async getImpuestos(context, payload) {
    const response = await axios.get(
      `api/Conceptos/ImpuestosByConcepto/${payload}`
    );
    return response.data.data;
  },
  async postImpuesto(context, payload) {
    const response = await axios.post(
      "api/conceptos/impuestoConcepto",
      payload
    );
    return response;
  },
  async deleteImpuesto(context, payload) {
    const response = await axios.delete(
      `api/conceptos/impuestoConcepto/${payload.concImpId}`
    );
    return response;
  },
  async getImpuestosList(context) {
    const response = await axios.get(`api/taxonomy/Impuestos`);
    return response.data.data;
  },
  async getConveniosAndConfig() {
    const response = await axios.get(
      `api/tiposComprobantes/conveniosAndConfig`
    );
    return response.data.data;
  },
  async getTiposComprobantesByConvenio(context, payload) {
    const response = await axios.get(
      `api/tiposComprobantes/tiposComprobantesByConvenio/${payload}`
    );
    return response.data.data;
  },
  async getTipoComprobanteById(context, payload) {
    const response = await axios.get(
      `api/tiposComprobantes/tipoComprobanteById/${payload}`
    );
    return response.data.data;
  },
  async postTiposComprobantes(context, payload) {
    const response = await axios.post("api/tiposComprobantes", payload);
    return response;
  },
  async copyConfigTiposComprobantes(context, payload) {
    const response = await axios.post(
      "api/tiposComprobantes/copyConfigComprobantes",
      payload
    );
    return response;
  },
  async deleteTipoComprobante(context, payload) {
    const response = await axios.delete(
      `api/tiposComprobantes/deleteTipoComprobante/${payload}`
    );
    return response;
  },
  async getGruposDevengamiento() {
    const response = await axios.get("api/GruposDevengamiento");
    return response.data.data;
  },
  async getGrupoDevengamientoPorId(context, payload) {
    const response = await axios.get(`api/GruposDevengamiento/${payload}`);
    return response.data.data;
  },
  async postGrupoDevengamiento(context, payload) {
    const response = await axios.post(
      "api/GruposDevengamiento/SaveGrupoDevengamiento",
      payload
    );
    return response;
  },

  async postVendedores(context, payload) {
    const response = await axios.post(
      "api/cobradores/vendedoresAfiliaciones",
      payload
    );
    return response;
  },

  async deleteGrupoDevengamiento(context, payload) {
    const response = await axios.delete(
      `api/GruposDevengamiento/DeleteGrupoDevengamiento/${payload}`
    );
    return response;
  },
  async getCompCtaCteAndTipo(context, payload) {
    const response = await axios.get(
      `api/tiposComprobantes/tCompCtaCte/${payload}`
    );
    return response.data.data;
  },
  async fetchComprobantesCtaCte(context, payload) {
    const response = await axios.post(
      "api/agentesCuenta/comprobantesCuentaCorriente",
      payload
    );
    return response.data.data;
  },
  async fetchEmpresasAportantes(context, payload) {
    const response = await axios.post(
      "api/empresas/fetchEmpresasAportantes",
      payload
    );
    return response.data.data;
  },
  async deleteEmpresaAportante(context, payload) {
    const response = await axios.delete(
      `api/empresas/deleteEmpresa/${payload.empId}`
    );
    return response;
  },
  async saveEmpresaAportante(context, payload) {
    const response = await axios.post("api/empresas/empresas", payload);
    return response;
  },
  async getSucursalesEmpresa(context, payload) {
    const response = await axios.get(
      `api/empresas/sucursalesEmpresas/${payload.empId}`
    );
    return response.data;
  },
  async saveSucursalEmpresa(context, payload) {
    const response = await axios.post(
      "api/empresas/sucursalesEmpresas",
      payload
    );
    return response;
  },
  async deleteSucursalEmpresa(context, payload) {
    const response = await axios.delete(
      `api/empresas/sucursalesEmpresas/${payload.sucEmpId}`
    );
    return response;
  },

  //Topes en remuneraciones
  async fetchTopesRemuneracionesByVigencia(context, payload) {
    const request = `api/DDJJAportes/topesRemuneraciones?vigencia=${payload}`;
    const response = await axios.get(request);
    return response.data.data;
  },

  async getTopeRemuneracionById(context, topeRemunId) {
    const response = await axios.get(
      `api/DDJJAportes/topeRemuneracion/${topeRemunId}`
    );
    return response.data.data;
  },
  async saveTopeRemuneracion(context, payload) {
    const response = await axios.post(
      "api/DDJJAportes/topeRemuneracion",
      payload
    );
    return response;
  },
  async deleteTopeRemuneracion(context, payload) {
    const response = await axios.delete(
      `api/DDJJAportes/topeRemuneracion/${payload.topeRemunId}`
    );
    return response;
  },

  //proceso de cobranza
  async getProcesosCobranza(context, payload) {
    const response = await axios.get(`api/procesosCobranza`);
    return response.data;
  },
  //procesos de cobranza config
  async getProcesosCobranzaConfig() {
    const response = await axios.get("api/procesosCobranza/configuracion");
    return response.data.data;
  },
  async saveProcesoCobranzaConfig(context, payload) {
    const response = await axios.post(
      "api/procesosCobranza/configuracion",
      payload
    );
    return response;
  },
  async deleteProcesoCobranzaConfig(context, payload) {
    const response = await axios.delete(
      `api/procesosCobranza/configuracion/${payload.procesoCobranzaConfigId}`
    );
    return response;
  },
  //procesos de cobranza por condicion de IVA
  async getProcesosCobranzaConfigCondicionIva(context, payload) {
    const response = await axios.get(
      `api/procesosCobranza/configuracion/condicion-iva/${payload}`
    );
    return response.data.data;
  },

  async saveCondIvaProcesoCobranzaConfig(context, payload) {
    const response = await axios.post(
      "api/procesosCobranza/configuracion/condicion-iva",
      payload
    );
    return response;
  },
  async deleteCondIvaProcesoCobranzaConfig(context, payload) {
    const response = await axios.delete(
      `api/procesosCobranza/configuracion/condicion-iva/${payload.procesoCobranzaCondIvaId}`
    );
    return response;
  },
  //procesos de cobranza de datos de envio
  async getProcesosConfigDeta(context, payload) {
    const response = await axios.post(
      "api/procesosCobranza/configuracion/detalle",
      payload
    );
    return response.data.data;
  },
  async saveDetalleProcesoCobranzaConfig(context, payload) {
    const response = await axios.post(
      "api/procesosCobranza/configuracion/saveDetalle",
      payload
    );
    return response;
  },

  async deleteDetalleProcesoCobranzaConfig(context, payload) {
    const response = await axios.delete(
      `api/procesosCobranza/configuracion/detalle/${payload.idDeta}`
    );
    return response;
  },
  async getConfigCosegurosCtaCte() {
    const response = await axios.get("api/cosegurosCtaCte");
    return response.data.data;
  },
  async saveCosegurosCtaCte(context, payload) {
    const response = await axios.post(
      "api/cosegurosCtaCte/configCosegurosCtaCte",
      payload
    );
    return response;
  },
  async deleteConfigCosegurosCtaCte(context, payload) {
    const response = await axios.delete(
      `api/cosegurosCtaCte/configCosegurosCtaCte/${payload}`
    );
    return response;
  },
  async getDevengamientosCosegurosCtaCte(context, payload) {
    const response = await axios.post(
      "api/cosegurosCtaCte/CosegurosConsultarProceso",
      payload
    );
    return response.data.data;
  },
  async anularProcesoDevengamientoCoseguro(context, payload) {
    const response = await axios.get(
      `api/cosegurosCtaCte/AnulacionDevengamientoCoseguro/${payload}`
    );
    return response;
  },
  async exportarExcelDevengamientoCoseguro(context, payload) {
    const response = await axios.get(
      `api/cosegurosCtaCte/ExportarExcelDevCoseguro/${payload}`
    );
    return response.data.data;
  },
  async nuevoProcesoCoseguroListaSeleccion(context, payload) {
    const response = await axios.post(
      "api/cosegurosCtaCte/NuevoProcesoCoseguroListaSeleccion",
      payload
    );
    return response.data.data;
  },
  async agrupaEncautoInteresesACobrar(context, payload) {
    const response = await axios.post(
      "api/cosegurosCtaCte/AgrupaAutorizacionesConceptosADevengar",
      payload
    );
    return response.data.data;
  },
  async agrupaEncautoComprobantesCtaCte(context, payload) {
    const response = await axios.post(
      "api/cosegurosCtaCte/AgrupaAutorizacionesComprobantesCtaCte",
      payload
    );
    return response.data.data;
  },
  async grabaProcesoInteresesACobrar(context, payload) {
    const response = await axios.post(
      "api/cosegurosCtaCte/GrabaProcesoEnConceptoADevengar",
      payload
    );
    return response.data.data;
  },
  async grabaProcesoComprobantesCtaCte(context, payload) {
    const response = await axios.post(
      "api/cosegurosCtaCte/GrabaProcesoEnComprobantesCtaCte",
      payload
    );
    return response.data.data;
  },

  async verAutorizacionCompleta(context, payload) {
    const response = await axios.get(
      `api/cosegurosCtaCte/VerAutorizacionCompleta/${payload}`
    );
    return response.data.data;
  },
  async verPracticasAutorizacion(context, payload) {
    const response = await axios.get(
      `api/cosegurosCtaCte/VerPracticasAutorizacion/${payload}`
    );
    return response.data.data;
  },
  //devengamiento adic extras.
  async consultaAdicionalesExtraGeneraComp(context, payload) {
    const response = await axios.post(
      "api/adicionalesExtra/ConsultaDevengamientoAdicionalesExtra",
      payload
    );
    return response.data.data;
  },
  async generaComprobantesDevAdiExtra(context, payload) {
    const response = await axios.post(
      "api/adicionalesExtra/GeneraComprobantesAdiExtra",
      payload
    );
    return response;
  },
  //Aportes esperados
  async fetchProcesosAportesEspByParams(context, payload) {
    const response = await axios.post(
      "api/aportesEsperados/fetchListaProcesoAporteEsperadoByParams",
      payload
    );
    return response.data.data;
  },
  async fetchListaProcesoAporteEsperadoAumentoByParams(context, payload) {
    const response = await axios.post(
      "api/aportesEsperados/FetchListaProcesoAporteEsperadoAumentoByParams",
      payload
    );
    return response;
  },
  async grabarAumentoMasivoAporteEsperado(context, payload) {
    const response = await axios.post(
      "api/aportesEsperados/GrabarAumentoMasivoAporteEsperado",
      payload
    );
    return response;
  },
  async anularProcesoActualizAporteEsperado(context, payload) {
    const response = await axios.post(
      "api/aportesEsperados/AnularProceso",
      payload
    );
    return response;
  },
  async getProcesoAporteEsperadoById(context, payload) {
    const response = await axios.get(
      `api/aportesEsperados/procesoAporteEsperado/${payload}`
    );
    return response.data.data;
  },
  async getFormatoProcesoImportacionAportesEsperados() {
    const response = await axios.get(
      "api/aportesEsperados/FormatoProcesoImportacionAportesEsperados"
    );
    return response.data.data;
  },
  async saveImportacionArchivoAportesEsperados(context, payload) {
    const response = await axios.post(
      "api/aportesEsperados/ImportacionAportesEsperados",
      payload
    );
    return response;
  }
};

export default actions;
