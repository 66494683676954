import axios from "@/axios/axios-api";

const actions = {
  async fetchTipoDeOperaciones() {
    const response = await axios.get(
      "api/formatosEmail/EmailOperacionesAppBenef"
    );
    return response.data;
  },
  async fetchEmailApp(context, payload) {
    const response = await axios.get(
      `api/formatosEmail/MultipleFormatos?codigo1=${payload.codigo1}&codigo2=${payload.codigo2}`
    );
    return response.data;
  },
  async postEmailApp() {
    const response = await axios.post(
      "api/configuracionParentescoApp",
      payload
    );
    return response;
  },
  async getFormatoEmail(context, payload) {
    const response = await axios.get(
      `api/formatosEmail/GetFormatoByCodigo?codigo=${payload.codigo}&id=${payload.id}`
    );
    return response.data;
  },
  async getParameters() {
    const response = await axios.get("api/parametrosGeneralesAppBenef");
    return response.data.data;
  },
  async getOpcionPrestador() {
    const response = await axios.get(
      "api/parametrosGeneralesAppBenef/OpcionesDomicilioPrestador"
    );
    return response.data.data;
  },
  async getEntidadesFacturantes() {
    const response = await axios.get("api/taxonomy/entidadesFacturantes");
    return response.data;
  },
  async getEntidadesFinancieras() {
    const response = await axios.get("api/taxonomy/entidadesFinancieras");
    return response.data;
  },
  async getConfigMercadoPago() {
    const response = await axios.get("api/configuracionMercadoPagoApp");
    return response.data;
  },
  async getConfigMercadoPagoById(context, payload) {
    const response = await axios.get(
      `api/configuracionMercadoPagoApp/${payload}`
    );
    return response.data;
  },
  async getPaymentMethods(context, payload) {
    const response = await axios.get(
      "api/configuracionMercadoPagoApp/PaymentMethods",
      payload
    );
    return response.data;
  },
  async getPaymentTypes(context, payload) {
    const response = await axios.get(
      "api/configuracionMercadoPagoApp/PaymentTypes",
      payload
    );
    return response.data;
  },
  async postParameters(context, payload) {
    const response = await axios.put(
      "api/parametrosGeneralesAppBenef",
      payload
    );
    return response;
  },
  async postInfoMedioPago(context, payload) {
    const response = await axios.put(
      "api/parametrosGeneralesAppBenef/InfoMedioDePago",
      payload
    );
    return response;
  },
  async postConfigMercadoPago(context, payload) {
    const response = await axios.post(
      "api/configuracionMercadoPagoApp",
      payload
    );
    return response;
  },
  async deleteConfigMercadoPago(context, payload) {
    const response = await axios.delete(
      `api/configuracionMercadoPagoApp/${payload.mpconfigId}`
    );
    return response;
  },
  async getConfiguracionParentescos() {
    const response = await axios.get("api/configuracionParentescoApp");
    return response.data.data;
  },
  async getConfiguracionParentescosById(context, payload) {
    const response = await axios.get(
      `api/configuracionParentescoApp/${payload}`
    );
    return response.data.data;
  },
  async getParentescos() {
    const response = await axios.get(
      "api/configuracionParentescoApp/parentescos"
    );
    return response.data.data;
  },
  async postConfiguracionParentescos(context, payload) {
    const response = await axios.post(
      "api/configuracionParentescoApp",
      payload
    );
    return response;
  },
  async deleteConfiguracionParentescos(context, payload) {
    const response = await axios.delete(
      `api/configuracionParentescoApp/${payload.configId}`
    );
    return response;
  },
  async getAppsPublicadas() {
    const result = await axios.get("api/apps");
    return result.data.data;
  },
  async getAppsPublicadasById(context, payload) {
    const response = await axios.get(`api/apps/${payload}`);
    return response.data.data;
  },
  async saveAppsPublicadas(context, payload) {
    const response = await axios.post("api/apps", payload);
    return response;
  },
  async deleteAppsPublicadas(context, payload) {
    const response = await axios.delete(`api/apps/${payload.appId}`);
    return response;
  },
  async fetchConvenios() {
    const response = await axios.get(`api/taxonomy/Convenios`);
    return response.data;
  },
  async fetchOrigenes() {
    const response = await axios.get(`api/taxonomy/Origenes`);
    return response.data.data;
  },
  async fetchTokenConfig() {
    const response = await axios.get(`api/apps/TokenConfig`);
    return response.data.data;
  },
  async getTokenConfigById(context, payload) {
    const response = await axios.get(`api/apps/TokenConfig/${payload}`);
    return response.data.data;
  },
  async saveTokenConfig(context, payload) {
    const response = await axios.post("api/apps/TokenConfig", payload);
    return response;
  },
  async deleteTokenConfig(context, payload) {
    const response = await axios.delete(`api/apps/TokenConfig/${payload.id}`);
    return response;
  },
  async setParametersToken() {
    const response = await axios.get("api/apps/ParametrosGenerales");
    return response.data.data;
  },
  async saveParametersToken(context, payload) {
    const response = await axios.post("api/apps/ParametrosGenerales", payload);
    return response;
  },
  async fetchPideToken() {
    const response = await axios.get("api/Taxonomy/PideToken");
    return response.data.data;
  },
  async getBotonesDePago() {
    const response = await axios.get("api/taxonomy/botonesDePago");
    return response.data.data;
  },
  async getConfiguracionesDePago(context, payload) {
    const response = await axios.get(`api/botonDePago/?bpId=${payload}`);
    return response.data.data;
  },
  async getMedioDePagoDetalleById(context, payload) {
    const response = await axios.get(`api/botonDePago/medioDePagoDetalle/${payload}`);
    return response.data.data;
  },
  async saveMedioDePago(context, payload) {
    const response = await axios.post("api/botonDePago", payload);
    return response;
  },
  async getMedioDePagoById(context, payload) {
    const response = await axios.get(`api/botonDePago/${payload.bpcId}`);
    return response.data.data;
  },
  async deleteConfiguracionDePago(context, payload) {
    const response = await axios.delete(`api/botonDePago/${payload.id}`);
    return response;
  },
};
export default actions;
