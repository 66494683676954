<template>
  <transition name="fade">
    <div v-show="loaded">
      <span v-show="changed" title="Hay cambios sin guardar"></span>
      <Editor
        v-if="editorCfg"
        api-key="no-api-key"
        :id="`text-editor-${uniqueId}`"
        :init="editorCfg.init"
        :plugins="editorCfg.plugins"
        :toolbar="editorCfg.toolbar"
        v-model="editorData"
      />
    </div>
  </transition>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "TextEditor",
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    setup: {
      type: Function,
      default() {}
    },
    changed: {
      type: Boolean,
      default: false
    },
    editorCfgProp: { type: Object, required: false, default: null }
  },
  data() {
    return {
      loaded: false,
      uniqueId: `${Date.now()}${Math.round(Math.random() * 9)}`,
      editorData: "",
      editorCfg: null
    };
  },
  computed: {},
  watch: {
    editorData(val) {
      this.$emit("update:value", val);
      this.$emit("update:changed", true);
    },
    value(val) {
      if (val != this.editorData) {
        this.editorData = val;
      }
    }
  },
  methods: {},
  mounted() {
    this.editorData = this.value;
    if (this.editorCfgProp != null) {
      this.editorCfg = {
        init: this.editorCfgProp.init,
        plugins: this.editorCfgProp.plugins,
        toolbar: this.editorCfgProp.toolbar
      };
      this.editorData = this.editorCfgProp.value;
      setTimeout(() => (this.loaded = true), 500);
    } else {
      this.editorCfg = {
        init: {
          language: "es",
          height: "70vh",
          image_uploadtab: true,
          images_upload_base_path: "/some/basepath",
          images_upload_url: "http://localhost:8080/uploadImage",
          branding: false,
          menubar: false,
          setup: this.setup
        },
        plugins: [
          "advlist autolink lists link image imagetools charmap preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount pagebreak"
        ],
        toolbar: `fontsizeselect | formatselect | bold italic underline |
        alignleft aligncenter alignright alignjustify |
        bullist numlist | image | table | source code | preview`
      };
      setTimeout(() => (this.loaded = true), 1000);
    }
  }
};
</script>

<style lang="scss" >
.tox-editor-header {
  svg {
    fill: #195472 !important;
  }
}
.tox-notification {
  display: none !important;
}

.tox .tox-button {
  &:not(.tox-button--secondary):not(.tox-button--naked) {
    background-color: var(--green-color);

    &:hover {
      background-color: var(--green-color-dark);
    }
  }
}
</style>
