import axios from "@/axios/axios-api";
import helpers from "@/utils/helpers.js";

const actions = {
  async fetchAfiliados(context, payload) {
    const response = await axios.post(
      "api/afiliados/gestionAfiliados",
      payload
    );
    return response.data.data;
  },
  async getConvenios() {
    const response = await axios.get("api/Taxonomy/Convenios");
    return response.data;
  },
  async fetchAgentesCtaByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const agentesCta = await axios.get(
        `api/taxonomy/autocompleteAgentesCta?input=${payload.input}`
      );
      return agentesCta.data.data;
    }
  },
  async getTiposDoc() {
    const response = await axios.get("api/taxonomy/tiposDoc");
    return response.data.data;
  },
  async deleteAfiliado(context, payload) {
    const response = await axios.delete(`api/afiliados/${payload.benId}`);
    return response;
  },
  async getNacionalidades() {
    const response = await axios.get("api/taxonomy/nacionalidades");
    return response.data;
  },
  async getDependenciasBenef() {
    const response = await axios.get("api/taxonomy/dependenciasBenef");
    return response.data;
  },
  async getTiposBenef() {
    const response = await axios.get("api/taxonomy/tiposBenef");
    return response.data;
  },
  async getEstadosCiviles() {
    const response = await axios.get("api/taxonomy/estadosCiviles");
    return response.data;
  },
  async getVendedores() {
    const response = await axios.get("api/taxonomy/vendedores");
    return response.data;
  },

  async postVendedores(context, payload) {
    const response = await axios.post(
      "api/cobradores/vendedoresAfiliaciones",
      payload
    );
    return response;
  },
  async getBarrios() {
    const response = await axios.get("api/taxonomy/barrios");
    return response.data.data;
  },
  async getLocalidades() {
    const response = await axios.get("api/taxonomy/localidades");
    return response.data.data;
  },
  async postAfiliado(context, payload) {
    const response = await axios.post("api/afiliados", payload);
    return response.data.data;
  },
  async postAfiliadoPreAlta(context, payload) {
    const response = await axios.post("api/afiliados/AfiliadoPreAlta", payload);
    return response;
  },
  async postFamiliar(context, payload) {
    const response = await axios.post("api/afiliados/saveFamiliar", payload);
    return response.data.data;
  },
  async getSubconveniosByConvenio(context, payload) {
    const response = await axios.get(`api/taxonomy/subconvenios/${payload}`);
    return response.data;
  },
  async getIntegrantesGrupoFamiliar(context, payload) {
    const response = await axios.get(
      `api/afiliados/integrantes/${payload.benGrId}?fechaConsulta=${payload.fechaConsulta}`
    );
    return response.data.data;
  },
  async getParentescos() {
    const response = await axios.get("api/afiliados/parentescosTit");
    return response.data.data;
  },
  async getAgenteCuentaByCodigo(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/agenteByCodigo/?codigo=${payload.codigo}&nombre=${payload.nombre}&dni=${payload.dni}&cuit=${payload.cuit}`
    );
    return response.data.data;
  },
  async getCondicionesIIBB() {
    const response = await axios.get("api/taxonomy/condicionesIIBB");
    return response.data;
  },
  async getConceptosIIBB() {
    const response = await axios.get("api/taxonomy/conceptosIIBB");
    return response.data;
  },
  async getZonas() {
    const response = await axios.get("api/taxonomy/zonas");
    return response.data;
  },
  async getSucPagos() {
    const response = await axios.get("api/taxonomy/sucPagos");
    return response.data;
  },
  async getTiposAgenteCta() {
    const response = await axios.get("api/taxonomy/tiposAgecta");
    return response.data;
  },
  async getTiposIva() {
    const response = await axios.get("api/taxonomy/tiposIva");
    return response.data;
  },
  async getTiposIvaAndTodas() {
    const response = await axios.get("api/taxonomy/TiposIvaTodas");
    return response.data.data;
  },
  async getTiposComprobantesDeudaIngreso() {
    const response = await axios.get(
      "api/taxonomy/tiposComprobantesDeudaIngreso"
    );
    return response.data.data;
  },
  async getNomencladores() {
    const response = await axios.get("api/taxonomy/nomencladores");
    return response.data.data;
  },
  async getDescCodigoByNomenclador(context, payload) {
    const response = await axios.get(
      `api/taxonomy/ListarNomenclador/${payload.nomenclador}/${payload.codigo}`
    );
    return response.data;
  },
  async getDescByNomencladorYCodigo(context, payload) {
    const response = await axios.get(
      `api/taxonomy/ObtenerNomenclador/${payload.nomenclador}/${payload.codigo}`
    );
    return response.data;
  },
  async getTiposComprobantesCancelatorios(context, payload) {
    const response = await axios.get(
      `api/taxonomy/comprobantesCancelatorios/${payload}`
    );
    return response.data.data;
  },
  async getJurisdiccionesIIBB() {
    const response = await axios.get("api/taxonomy/jurisdiccionesIIBB");
    return response.data;
  },
  async getCobradores() {
    const response = await axios.get("api/taxonomy/cobradores");
    return response.data;
  },
  async postAgenteCuenta(context, payload) {
    const response = await axios.post("api/agentesCuenta", payload);
    return response;
  },
  async postDatosAdicionalesAgenteCuenta(context, payload) {
    const response = await axios.post(
      "api/afiliados/ageCta/datosAdicionales",
      payload
    );
    return response;
  },
  async saveBenefAgeCta(context, payload) {
    const response = await axios.post(
      "api/afiliados/ageCta/saveBenefAgeCta",
      payload
    );
    return response;
  },
  async getParametrosGralesBenef() {
    const response = await axios.get("api/afiliados/parametrosGralesBenef");
    return response.data;
  },
  async getTiposRadic() {
    const response = await axios.get("api/taxonomy/tiposRadic");
    return response.data;
  },
  async getGerenciadoras() {
    const response = await axios.get("api/taxonomy/gerenciadoras");
    return response.data;
  },
  async getNivelesAtencion() {
    const response = await axios.get("api/taxonomy/NivelesAtencion");
    return response.data;
  },
  async getAfiliadoById(context, payload) {
    const response = await axios.get(
      `api/afiliados/${payload.benId}/${payload.fechaConsulta}`
    );
    return response.data.data;
  },
  async getAfiliadoPreAltaById(context, payload) {
    const response = await axios.get(
      `api/afiliados/AfiliadoPreAlta/${payload}`
    );
    return response.data.data;
  },
  async getPlanesByConvenioAndVigencia(context, payload) {
    const response = await axios.get(
      `api/taxonomy/planesByConvenioAndVigencia/${payload.convId}/${payload.fechaAlta}/${payload.planActual}?incluirNoVig=${payload.incluirNoVig}`
    );
    return response.data.data;
  },
  async getConveniosByVigencia(context, payload) {
    const response = await axios.get(
      `api/taxonomy/conveniosByVigencia/${payload.fechaAlta}`
    );
    return response.data.data;
  },
  async getEmpresasAfiliado(context, payload) {
    let response = null;
    if (payload.fechaConsulta === null)
      response = await axios.get(`api/empresas/benefEmpresas/${payload.benId}`);
    else
      response = await axios.get(
        `api/empresas/benefEmpresas/${payload.benId}?fechaConsulta=${payload.fechaConsulta}`
      );
    return response.data.data;
  },
  async getEmpresasAfiliadoById(context, payload) {
    const response = await axios.get(
      `api/empresas/empresaAfiliadoById/${payload}`
    );
    return response.data.data;
  },
  async saveEmpresasAfiliado(context, payload) {
    const response = await axios.post("api/empresas", payload);
    return response;
  },
  async deleteEmpresasAfiliado(context, payload) {
    const response = await axios.delete(`api/empresas/${payload.benefEmpId}`);
    return response;
  },
  async getSucursalesByEmpresa(context, payload) {
    const response = await axios.get(
      `api/taxonomy/sucursalesByEmpresa/${payload.empId}`
    );
    return response.data.data;
  },
  async getCategoriasByEmpresa(context, payload) {
    const response = await axios.get(
      `api/taxonomy/categoriasSindicalesByEmpresa/${payload.empId}`
    );
    return response.data.data;
  },
  async getAgenteCuentaByBenGr(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/agenteByBenGr/${payload.benGrId}/${payload.fechaConsulta}`
    );
    return response.data.data;
  },
  async getHistorialAgentes(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/historialAgentes/${payload.benGrId}`
    );
    return response.data.data;
  },
  async saveAgenteCuentaFromTitular(context, payload) {
    const response = await axios.post(
      `api/agentesCuenta/saveAgenteCuentaFromTitular/${payload.benGr}`
    );
    return response;
  },
  async saveProveedorTitular(context, payload) {
    const response = await axios.post("api/proveedores", payload);
    return response;
  },
  async saveDatosAdicionalesProveedor(context, payload) {
    const response = await axios.post(
      "api/proveedores/datosAdicionalesProveedor",
      payload
    );
    return response;
  },
  async updateProveedorInBenef(context, payload) {
    const response = await axios.post(
      "api/afiliados/saveProveedoresBenef",
      payload
    );
    return response;
  },
  async getProveedorTitById(context, payload) {
    const response = await axios.get(
      `api/proveedores/proveedorByCodigo/${payload.provId}`
    );
    return response.data.data;
  },
  async saveEmpresaPorDefecto(context, payload) {
    const response = await axios.post("api/empresas/empresaDefecto", payload);
    return response;
  },
  async getAportesEsperados(context, payload) {
    const response = await axios.get(
      `api/aportesEsperados/aportesEsperados/${payload}`
    );
    return response.data.data;
  },
  async getTopeImporteAportesEsperados() {
    const response = await axios.get(
      "api/aportesEsperados/topeImporteAportesEsperados"
    );
    return response.data.data;
  },
  async getAporteEsperadoById(context, payload) {
    const response = await axios.get(
      `api/aportesEsperados/aporteEsperado/${payload}`
    );
    return response.data.data;
  },
  async saveAporteEsperado(context, payload) {
    const response = await axios.post("api/aportesEsperados", payload);
    return response;
  },
  async saveAndCloseVigencia(context, payload) {
    const response = await axios.post(
      "api/aportesEsperados/saveAndCloseVigencia",
      payload
    );
    return response;
  },
  async deleteAporteEsperado(context, payload) {
    const response = await axios.delete(
      `api/aportesEsperados/aporteEsperado/${payload}`
    );
    return response;
  },
  async saveNuevaEmpresaAfiliado(context, payload) {
    const response = await axios.post("api/empresas/empresas", payload);
    return response;
  },
  async getRamasSindicales() {
    const response = await axios.get("api/taxonomy/ramasSindicales");
    return response.data.data;
  },
  async fetchOtrasAfiliaciones(context, payload) {
    let response = null;
    if (payload.fechaConsulta == null)
      response = await axios.get(
        `/api/afiliados/FetchOtrasAfiliaciones/${payload.benId}`
      );
    else
      response = await axios.get(
        `/api/afiliados/FetchOtrasAfiliaciones/${payload.benId}?fechaConsulta=${payload.fechaConsulta}`
      );
    return response.data.data;
  },
  async deleteRegistroOtrasAfiliaciones(context, payload) {
    const response = await axios.delete(
      `/api/Afiliados/OtraAfiliacion/${payload}`
    );
    return response;
  },
  async saveOtraAfiliacion(context, payload) {
    const response = await axios.post("/api/Afiliados/OtraAfiliacion", payload);
    return response;
  },
  async getOtroTipoNumeroAfiliado() {
    const response = await axios.get("/api/Taxonomy/OtroTipoNumeroAfiliado");
    return response.data;
  },
  async getEstadosBenef() {
    const response = await axios.get("/api/Afiliados/EstadosBenef");
    return response.data.data;
  },

  async getAfiliadoSimpleById(context, payload) {
    const response = await axios.get(
      `api/Afiliados/DatosAfiliadoBaja/${payload}`
    );
    return response.data.data;
  },
  async setBenApeCodificado(context, payload) {
    const response = await axios.get(
      `api/Afiliados/afiliadoCodificado/${payload}`
    );
    return response.data.data;
  },
  async generarAfiCodificado(context, payload) {
    const response = await axios.post(
      "/api/Afiliados/afiliadoCodificado",
      payload
    );
    return response;
  },

  async fetchIntegrantesConCobertura(context, payload) {
    const response = await axios.get(
      `api/Afiliados/IntegrantesConCobertura/${payload.benId}/${payload.fechaConsulta}`
    );
    return response.data.data;
  },
  async postBajaAfiliado(context, payload) {
    const response = await axios.post("/api/Afiliados/BajaAfiliado", payload);
    return response;
  },
  async getParametrosGeneralesEmpreas() {
    const response = await axios.get("api/empresas/parametrosGralesEmpresas");
    return response.data.data;
  },
  async getModalidadesPagoProveedores() {
    const response = await axios.get("api/taxonomy/modalidadesPago");
    return response.data.data;
  },
  async getNuevoMovimiento(context, payload) {
    const response = await axios.get(`api/Afiliados/NuevoMovimiento`, {
      params: { benId: payload.benId, date: payload.date }
    });
    return response.data.data;
  },
  async getBonificacionesRecargosByBenGrId(context, payload) {
    const response = await axios.get(
      `api/Afiliados/BonificaRecargoBenef/${payload}`
    );
    return response.data.data;
  },
  async hasTitularByBenGrId(context, payload) {
    const response = await axios.get(
      `api/Afiliados/HasTitularByBenGrId/${payload}`
    );
    return response.data.data;
  },
  async hasAgenteCtaByBenGrId(context, payload) {
    const response = await axios.get(
      `api/AgentesCuenta/HasAgenteCuentaByBenGrId/${payload}`
    );
    return response.data.data;
  },
  async postNuevoMovimiento(context, payload) {
    const response = await axios.post(
      "/api/Afiliados/NuevoMovimiento",
      payload
    );
    return response;
  },
  async getParametrosGeneralesEmpresas() {
    const response = await axios.get("api/empresas/parametrosGralesEmpresas");
    return response.data;
  },
  async getPermisosAgenteCuenta(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/permisosAgenteCuenta/${payload.optionCode}`
    );
    return response.data.data;
  },
  async getMotivosBaja(context, payload) {
    const response = await axios.get(`api/Afiliados/MotivosBaja/${payload}`);
    return response.data.data;
  },
  async fetchBeneficiosAfiliado(context, payload) {
    let response = null;
    if (payload.fechaConsulta == null)
      response = await axios.get(
        `/api/afiliados/BeneficiosAfiliado/${payload.benId}`
      );
    else
      response = await axios.get(
        `/api/afiliados/BeneficiosAfiliado/${payload.benId}?fechaConsulta=${payload.fechaConsulta}`
      );
    return response.data.data;
  },
  async getTiposBeneficiosBenef() {
    const response = await axios.get(`api/Taxonomy/TiposBeneficios`);
    return response.data.data;
  },
  async saveBeneficioAfiliado(context, payload) {
    const response = await axios.post(
      "/api/Afiliados/BeneficiosAfiliado",
      payload
    );
    return response;
  },
  async deleteBeneficioAfiliado(context, payload) {
    const response = await axios.delete(
      `/api/Afiliados/BeneficiosAfiliado/${payload}`
    );
    return response;
  },
  async getMedicosCabeceraAfiliado(context, payload) {
    const response = await axios.get(`api/medicosCabecera/${payload}`);
    return response.data.data;
  },
  async saveMedicosCabeceraAfiliado(context, payload) {
    const response = await axios.post("api/medicosCabecera", payload);
    return response;
  },
  async deleteMedicoCabeceraAfiliado(context, payload) {
    const response = await axios.delete(`api/medicosCabecera/${payload}`);
    return response;
  },
  async fetchPrestadoresByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const prestadores = await axios.get(
        `api/prestadores/autocompleteCartilla?nombrePrestador=${payload.input}`
      );
      return prestadores.data.data;
    }
  },

  async getGerenciadorasBenef(context, payload) {
    let response = null;
    if (payload.fechaConsulta == null)
      response = await axios.get(
        `/api/afiliados/gerenciadoras-afiliado/${payload.benId}`
      );
    else
      response = await axios.get(
        `/api/afiliados/gerenciadoras-afiliado/${payload.benId}?fechaConsulta=${payload.fechaConsulta}`
      );
    return response.data.data;
  },
  async saveGerenciadoraBenef(context, payload) {
    const response = await axios.post(
      "api/afiliados/gerenciadoras-afiliado",
      payload
    );
    return response;
  },
  async deleteGerenciadoraBenef(context, payload) {
    const response = await axios.delete(
      `api/afiliados/gerenciadoras-afiliado/${payload.gerBenId}`
    );
    return response;
  },
  async fetchConfigMovMasivosByParams(context, payload) {
    const response = await axios.post(
      "api/movimientosMasivos/configuracionProcesosCambioBenef",
      payload
    );
    return response.data.data;
  },
  async getVariablesYOperacionesMovMasivos() {
    const response = await axios.get(
      "api/movimientosMasivos/variablesYOperaciones"
    );
    return response.data.data;
  },
  //mov. masivo de padrones
  async fetchProcesosMovimientosMasivosByFilters(context, payload) {
    const response = await axios.post(
      "api/movimientosMasivos/Procesos",
      payload
    );
    return response.data.data;
  },
  async getMovimientosMasivoById(context, payload) {
    const response = await axios.get(
      `api/movimientosMasivos/Procesos/${payload}`
    );
    return response.data.data;
  },
  async anularProcesoMovimientoMasivo(context, payload) {
    const response = await axios.post(
      `api/movimientosMasivos/AnularProceso/${payload.bcProcId}`
    );
    return response;
  },
  async validarFormulaConfigMovMasivo(context, payload) {
    const response = await axios.post(
      "api/movimientosMasivos/validaFormula",
      payload
    );
    return response.data.data;
  },
  async getCambiosConvenioPlanById(context, payload) {
    const response = await axios.get(
      `api/movimientosMasivos/cambiosOsPlanes/${payload}`
    );
    return response.data.data;
  },
  async getMotivosTipoBajaMovMasivos(context, payload) {
    const response = await axios.get(
      `api/movimientosMasivos/motivoTipoBaja/${payload}`
    );
    return response.data.data;
  },
  async postConfigProcesoMovMasivos(context, payload) {
    const response = await axios.post(
      "api/movimientosMasivos/saveConfigProcesoMovimientosMasivos",
      payload
    );
    return response;
  },
  async deleteProcesoConfigMovMasivos(context, payload) {
    const response = await axios.delete(
      `api/movimientosMasivos/deleteConfigProcesoMovimientosMasivos/${payload}`
    );
    return response;
  },
  async createNuevoProcesoMovimientoMasivo(context, payload) {
    const response = await axios.post(
      "api/movimientosMasivos/NuevoProceso",
      payload
    );
    return response.data.data;
  },
  async postExcelNuevoProcesoMovimientoMasivo(context, payload) {
    const response = await axios.post(
      "api/MovimientosMasivos/ValidaArchivoBajaMasiva",
      payload
    );
    return response;
  },
  async generarProcesoMovimientoMasivo(context, payload) {
    const response = await axios.post(
      "api/movimientosMasivos/GenerarProcesoMovimientoMasivo",
      payload
    );
    return response;
  },
  async getFormatoArchivoMovMasivoBaja(context, payload) {
    const response = await axios.get(
      "api/MovimientosMasivos/FormatoArchivoMovMasivoBaja"
    );
    return response;
  },
  async getPermissionsConfigMovMasivos(context, payload) {
    const response = await axios.get(
      `api/movimientosMasivos/permisosConfigMovMasivos/${payload.optionCode}`
    );
    return response.data.data;
  },
  async getConfigBajasAutomaticasConvenios() {
    const response = await axios.get(
      `api/afiliados/configBajasAutomaticasConvenios`
    );
    return response.data.data;
  },
  async getConfigBajasAutomaticasParentescos() {
    const response = await axios.get(
      `api/afiliados/configBajasAutomaticasParentescos`
    );
    return response.data.data;
  },
  async getConfigBajasAutomaticasCoberturasEspeciales() {
    const response = await axios.get(
      `api/afiliados/configBajasAutomaticasCoberturasEspeciales`
    );
    return response.data.data;
  },
  async postConfigBajasAutomaticas(context, payload) {
    const response = await axios.post(
      `api/afiliados/configBajasAutomaticas`,
      payload
    );
    return response;
  },
  async getTiposMovMotivos() {
    const response = await axios.get(`api/taxonomy/tiposMovMotivos`);
    return response.data;
  },
  async fetchTipoCoberturaDocEstado() {
    const response = await axios.get(`api/taxonomy/TipoCoberturaDocEstado`);
    return response.data;
  },
  async getBajasAutomaticas(context, payload) {
    const response = await axios.post(
      "/api/Afiliados/ProcesosBajaAutomatica",
      payload
    );
    return response.data.data;
  },
  async getDetalleBajaAutomaticaById(context, payload) {
    const response = await axios.get(
      `/api/Afiliados/ProcesosBajaAutomatica/${payload}`
    );
    return response.data.data;
  },
  async anularProcesoBajaAutomatica(context, payload) {
    const response = await axios.post(
      `/api/Afiliados/AnularProcesoBajaAutomatica/${payload}`
    );
    return response;
  },
  //documentacion de tipo de cobertura especial
  async getPermissionsDocumentCoberturaEspecial(context, payload) {
    const response = await axios.get(
      `api/CoberturasEspeciales/TiposCoberturasEspeciales/DocumentacionPermisos/${payload.optionCode}`
    );
    return response.data.data;
  },
  async getDocumentsCoberturaEspecial(context, payload) {
    const response = await axios.get(
      `api/CoberturasEspeciales/TiposCoberturasEspeciales/Documentacion/${payload}`
    );
    return response.data.data;
  },
  async saveDocumentCoberturaEspecial(context, payload) {
    const response = await axios.post(
      "api/CoberturasEspeciales/TiposCoberturasEspeciales/Documentacion",
      payload
    );
    return response;
  },
  async heredarDocumentsCoberturaEspecial(context, payload) {
    const response = await axios.post(
      `api/CoberturasEspeciales/TiposCoberturasEspeciales/HeredarDocumentacion?tipoCobId=${payload.tipoCobId}&tipoCobToCopyId=${payload.tipoCobToCopyId}`
    );
    return response;
  },
  async deleteDocumentCoberturaEspecial(context, payload) {
    const response = await axios.delete(
      `api/CoberturasEspeciales/TiposCoberturasEspeciales/Documentacion/${payload}`
    );
    return response;
  },
  async getCoberturasEspecialesParaDocumentacion(context, payload) {
    const response = await axios.get(
      `api/coberturasEspeciales/tiposCoberturasEspeciales?IdToExclude=${payload}`
    );
    return response.data.data;
  },
  async getTipoCoberturasEspeciales(context, payload) {
    const response = await axios.get(
      "api/coberturasEspeciales/tiposCoberturasEspeciales",
      payload
    );
    return response.data.data;
  },
  async saveTipoCoberturaEspecial(context, payload) {
    const response = await axios.post(
      "api/coberturasEspeciales/tiposCoberturasEspeciales",
      payload
    );
    return response;
  },
  async deleteTipoCobEspecial(context, payload) {
    const response = await axios.delete(
      `api/coberturasEspeciales/tiposCoberturasEspeciales/${payload}`
    );
    return response;
  },
  async getCoberturasEspeciales(context, payload) {
    const response = await axios.post("api/coberturasEspeciales", payload);
    return response.data.data;
  },
  async getValidacionCoberturaEspecialByTipoCob(context, payload) {
    const response = await axios.get(
      `api/CoberturasEspeciales/ValidacionesByTipoCob/${payload}`
    );
    return response.data.data;
  },
  async fetchTiposCoberturas() {
    const response = await axios.get("api/Taxonomy/TiposCoberturas");
    return response.data;
  },
  async getDatosCoberturaEspecialById(context, payload) {
    const response = await axios.get(
      `/api/coberturasEspeciales/DatosCoberturaEspecial/${payload}`
    );
    return response.data.data;
  },
  async saveCoberturaespecial(context, payload) {
    const response = await axios.post(
      "api/CoberturasEspeciales/SaveCoberturaEspecial",
      payload
    );
    return response;
  },
  async deleteCoberturaEspecial(context, payload) {
    const response = await axios.delete(
      `api/CoberturasEspeciales/DeleteCoberturaEspecial/${payload}`
    );
    return response;
  },
  async buscarAfiliados(context, payload) {
    const response = await axios.get(
      `api/afiliados/?nombre=${payload.benNom}&apellido=${payload.benApe}&cuil=${payload.cuil}&numero=${payload.numero}&dni=${payload.docId}&agenteCuenta=${payload.agenteCuenta}`
    );
    return response.data.data;
  },
  async getDocumentacionCoberturaEspAfiliado(context, payload) {
    const response = await axios.get(
      `/api/coberturasEspeciales/DocumentacionCoberturaEspecial/${payload}`
    );
    return response;
  },
  async fetchAfiliadosCoberturaEspecialByQuery(context, payload) {
    const response = await axios.post(
      "api/taxonomy/AfiliadosBenIdNombre",
      payload
    );
    return response.data;
  },
  async saveDocumentacionCoberturaEspecial(context, payload) {
    const response = await axios.post(
      "api/coberturasEspeciales/SaveDocCoberturaEspecial",
      payload
    );
    return response;
  },
  async fetchArchivosDocumentacionCobertura(context, payload) {
    const response = await axios.get(
      `/api/coberturasEspeciales/ArchivosDocCobertura/${payload}`
    );
    return response.data.data;
  },
  async getAfiliadosByNumero(context, payload) {
    const response = await axios.get(
      `api/Afiliados/AfiliadosByNumero/${payload}`
    );
    return response.data.data;
  },
  async getArchivoDocCobertura(context, payload) {
    try {
      const response = await axios.get(
        `api/CoberturasEspeciales/Archivo?asId=${payload.asId}`,
        { responseType: "blob" }
      );
      let fileExtension = response.data.type.split("/").pop();

      helpers.downloadFile(response.data, payload.nombreArchivo, fileExtension);
    } catch (error) {
      {
        if (error.response) {
          let responseToJson = JSON.parse(await error.response.data.text());
          let errorMessage = responseToJson.errors[0];
          context.commit(
            "user/setAlertMessage",
            {
              type: "warning",
              message: errorMessage
            },
            { root: true }
          );
          reject(error.response.status);
        }
      }
    }
  },
  async guardarArchivoExpediente(context, payload) {
    const response = await axios.post(
      `api/coberturasEspeciales/SaveArchivoDocumentacion`,
      payload.formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response;
  },
  async deleteArchivoDocumentacion(context, payload) {
    const response = await axios.delete(
      `api/CoberturasEspeciales/DeleteArchivoDocCoberturaEsp/${payload.asId}`
    );
    return response;
  },
  async getLimiteDiasMovimiento(context, payload) {
    const response = await axios.get("api/Afiliados/getLimiteDiasMovimiento");
    return response.data.data;
  },
  async getPermisosCoseguros(context, payload) {
    const response = await axios.get(
      `api/coberturasEspeciales/tiposCoberturasEspeciales/cosegurosPermisos/${payload}`
    );
    return response.data.data;
  },
  async getCoseguroTipoCoberturaEspecial(context, payload) {
    const response = await axios.post(
      "api/coberturasEspeciales/tiposCoberturasEspeciales/getCoseguros",
      payload
    );
    return response.data.data;
  },
  async saveCoseguroTipoCoberturaEspecial(context, payload) {
    const response = await axios.post(
      "api/coberturasEspeciales/tiposCoberturasEspeciales/coseguros",
      payload
    );
    return response;
  },
  async getGrupos(context, payload) {
    const response = await axios.get(`api/taxonomy/grupos/${payload}`);
    return response.data;
  },
  async deleteCoseguroTipoCob(context, payload) {
    const response = await axios.delete(
      `api/coberturasEspeciales/tiposCoberturasEspeciales/coseguros/${payload}`
    );
    return response;
  },
  async getPermissionsTopesCoberturasEspeciales(context, payload) {
    const response = await axios.get(
      `api/coberturasEspeciales/tiposCoberturasEspeciales/topesPermisos/${payload.optionCode}`
    );
    return response.data.data;
  },
  async getTopesPorTipoCoberturaEspecial(context, payload) {
    const response = await axios.post(
      "api/coberturasEspeciales/tiposCoberturasEspeciales/getTopes",
      payload
    );
    return response.data.data;
  },
  async saveTopePorTipoCoberturaEspecial(context, payload) {
    const response = await axios.post(
      "api/coberturasEspeciales/tiposCoberturasEspeciales/topes",
      payload
    );
    return response;
  },
  async deleteTopePorTipoCoberturaEspecial(context, payload) {
    const response = await axios.delete(
      `api/coberturasEspeciales/tiposCoberturasEspeciales/topes/${payload}`
    );
    return response;
  },
  async getConveniosSPadron() {
    const response = await axios.get("api/taxonomy/GetConveniosSinPadron");
    return response.data;
  },
  async saveAfiliadoConvenioSPadron(context, payload) {
    const response = await axios.post(
      "api/Afiliados/SaveAfiliadoConvenioSinPadron",
      payload
    );
    return response.data.data;
  },
  async getGeneros() {
    const response = await axios.get("api/taxonomy/GetGeneros");
    return response.data.data;
  },
  async getVtoCredencialAfiliado(context, payload) {
    const response = await axios.get(`api/afiliados/getVtoCredencial/${payload.fechaAlta}/${payload.osId}`);
    return response.data.data;
  }
};
export default actions;
