//import { mapGetters } from 'vuex';
import axios from "@/axios/axios-api";
import getAPIUrl from "@/utils/helpers/getAPIUrl";

const actions = {
  async fetchProveedoresByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const response = await axios.get(
        `api/taxonomy/findProveedores?search=${payload.input}`
      );
      return response.data;
    }
  },
  async getTiposValoresModuloProveedores() {
    const response = await axios.get(
      "api/taxonomy/tiposValoresModuloProveedores"
    );
    return response.data;
  },
  async consultaOrdenesPago(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/consultaOrdenesPago",
      payload
    );
    return response.data.data;
  },
  async getDetalleOrdenPago(context, payload) {
    const response = await axios.get(
      `api/ordenesPago/detalleOrdenPago/${payload}`
    );
    return response.data.data;
  },
  async fetchTipoValoresRetenciones(context, payload) {
    const response = await axios.get(
      `api/taxonomy/tipoValoresRetenciones/${payload}`
    );
    return response.data.data;
  },
  async getTiposComprobantesOPByFormatoImpresion(context, payload) {
    const response = await axios.get(
      `api/taxonomy/tipoComprobantesOrdenesPago/${payload}`
    );
    return response.data.data;
  },
  async fetchFormatoImpresionVariables(context, payload) {
    const response = await axios.get(
      `api/taxonomy/formatoImpresionVariables/${payload}`
    );
    return response.data;
  },
  async fetchFormatoImpresionTipo(context, payload) {
    const response = await axios.get(
      `api/taxonomy/formatoImpresionTipo/${payload}`
    );
    return response.data;
  },
  async fetchFormatoImpresionOP() {
    const response = await axios.get("api/ordenesPago/formatoImpresionOP");
    return response.data.data;
  },
  async saveFormatoImpresionOP(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/formatoImpresionOP",
      payload
    );
    return response;
  },
  async deleteFormatoImpresionOP(context, payload) {
    const response = await axios.delete(
      `api/ordenesPago/formatoImpresionOP/${payload}`
    );
    return response;
  },
  async imprimirArchivoOrdenesPagoPdf(context, payload) {
    const token = localStorage.getItem("token");
    const urlAPI = await getAPIUrl();
    return new Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();
      if (process.env.NODE_ENV == "development") {
        xhr.open("POST", `${urlAPI}/api/ordenesPago/impresionOrdenPago`, true);
      } else {
        xhr.open("POST", `${
          urlAPI.endsWith("/") ? urlAPI.slice(0, -1) : urlAPI
        }/api/ordenesPago/impresionOrdenPago`, true);
      }
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Content-type", "application/json;charset=UTF-8");
      xhr.responseType = "arraybuffer";

      let params = {
        id: payload.id,
        tipoImpresion: payload.tipoImpresion
      };
      xhr.send(JSON.stringify(params));

      // en Firefox no funciona con este tratamiento el preview del navegador para imprimir un archivo
      // por lo que en la parte del "else" lo descarga como pdf directamente
      // VER TRATAMIENTO PARA QUE SE PUEDA IMPRIMIR DIRECTAMENTE
      if (payload.browser !== "F") {
        xhr.onload = e => {
          if (e.target.status === 200) {
            let blob = new Blob([e.target.response], {
              type: "application/pdf"
            });
            let objFra = document.querySelector("#imprimirOrdenPago");
            if (objFra === null) {
              objFra = document.createElement("iframe");
              objFra.setAttribute("id", "imprimirOrdenPago");
            } else {
              objFra = document.getElementById("imprimirOrdenPago");
            }
            objFra.style.visibility = "hidden";
            objFra.src = window.URL.createObjectURL(blob);
            document.body.appendChild(objFra);
            objFra.contentWindow.focus();
            objFra.contentWindow.print();
          } else {
            context.commit(
              "user/setAlertMessage",
              {
                type: "warning",
                message: "No se pudo recuperar el archivo."
              },
              { root: true }
            );
          }
        };
      } else {
        xhr.onload = e => {
          if (e.target.status === 200) {
            resolve(e.target.status);
            const fileName = payload.fileName;
            let blob = new Blob([e.target.response], {
              type: "application/pdf"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", fileName);
            link.download = fileName;
            link.click();
          } else {
            context.commit(
              "user/setAlertMessage",
              {
                type: "warning",
                message: "No se pudo recuperar el archivo"
              },
              { root: true }
            );
            reject(e.target.status);
          }
        };
      }
    });
  },
  async crearProcesoEnvioMasivoOP(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/CrearProcesoEnvioMasivoOP",
      payload
    );
    return response;
  },
  async getHistorialEnviosOP(context, payload) {
    const response = await axios.get(
      `api/ordenesPago/HistorialEnviosOP/${payload}`
    );
    return response.data.data;
  },
  async envioEmailIndividualOP(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/SendEmailIndividualOP",
      payload
    );
    return response;
  },
  async getProcesoEnvioEmailMasivoOP(context, payload) {
    const response = await axios.post(
      "api/envioEmailMasivo/getProcesoEnvioEmailMasivoOP",
      payload
    );
    return response.data;
  },
  async fetchTiposProveedores() {
    const response = await axios.get("api/taxonomy/tiposProveedores/");
    return response.data;
  },
  async anularProcesoEnvioEmailMasivoOP(context, payload) {
    const response = await axios.delete(
      `api/envioEmailMasivo/anularProcesoEmailMasivoOP/${payload}`
    );
    return response;
  },
  async reenviarProcesoEnvioEmailMasivoOP(context, payload) {
    const response = await axios.get(
      `api/envioEmailMasivo/reenviarProcesoEmailMasivoOP/${payload}`
    );
    return response;
  },
  async fetchTiposComprobantesOP() {
    const response = await axios.get("api/taxonomy/tiposComprobantesOP/");
    return response.data;
  },
  async fetchEnviosConfiguracionOrdenesPago() {
    const response = await axios.get(
      "api/enviosConfiguracion/enviosConfiguracionOP"
    );
    return response.data.data;
  },
  async deleteEnvioConfiguracionOrdenesPago(context, payload) {
    const response = await axios.delete(
      `api/enviosConfiguracion/enviosConfiguracionOP/${payload}`
    );
    return response;
  },
  async getProcesosEnviosEstandarByModulo(context, payload) {
    const response = await axios.get(
      `api/taxonomy/procesosEnviosEstandar/${payload}`
    );
    return response.data;
  },
  async getEnvioConfiguracionOrdenesPagoById(context, payload) {
    const response = await axios.get(
      `api/enviosConfiguracion/enviosConfiguracionOP/${payload}`
    );
    return response.data.data;
  },
  async getProcesoEnvioOrdenesPagoDetalleById(context, payload) {
    const response = await axios.get(
      `api/enviosConfiguracion/enviosConfiguracionOP/procesoDetalle/${payload}`
    );
    return response.data.data;
  },
  async saveEnvioConfiguracionOrdenesPago(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/enviosConfiguracionOP",
      payload
    );
    return response;
  },
  async getVariablesEnviosEstandarByModulo(context, payload) {
    const response = await axios.get(
      `api/taxonomy/variablesEnviosEstandar/${payload}`
    );
    return response.data;
  },
  async saveLogEnviosEstandarOrdenesPago(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/logEnviosEstandarOrdenesPago",
      payload
    );
    return response;
  },
  async getCtaCteProveedoresByFilter(context, payload) {
    const response = await axios.post("api/proveedores/getCuentaCorrienteProveedores", payload);
    return response.data.data;
  },
  async getComprobanteProveedorById(context, payload) {
    const response = await axios.get(`api/proveedores/getComprobanteById/${payload}`);
    return response.data.data;
  },
  async getParametroGralFechaDesde(context, payload) {
    const response = await axios.get(`api/proveedores/parametroGralFechaDesde`);
    return response.data.data;
  },
};
export default actions;
