<template>
  <section class="login">
    <div class="container-fluid h-100">
      <div class="row justify-content-center align-items-center h-100">
        <div class="left col-md-6">
          <div
            class="full-height d-flex flex-column justify-content-between align-items-center green-column"
          >
            <div></div>
            <img
              :src="
                customLogo != null
                  ? customLogo
                  : require('@/assets/img/logo.svg')
              "
              alt="Geclipsa"
              class="logo"
            />
            <div class="copy"></div>
          </div>
        </div>
        <div class="right col-md-6">
          <div
            class="full-height d-flex flex-column justify-content-between align-items-center white-column"
          >
            <div></div>
            <div class="login-box">
              <div class="text-center">
                <img
                  src="@/assets/img/logo_int.svg"
                  alt="Geclipsa"
                  class="small-logo"
                  height="80px"
                  width="80px"
                />
                <h6 class="text-green">
                  <a
                    :href="`${lastVersion}${currentVersion}.pdf`"
                    style="text-decoration: none;"
                    target="_blank"
                    >{{ currentVersion }}</a
                  >
                </h6>
                <h2
                  class="rubic text-green font-weight-bold title-sign"
                  style="font-size:0.9em"
                >
                  Iniciar sesión
                </h2>
                <v-form
                  v-model="isFormValid"
                  ref="login-form"
                  id="login-form"
                  @submit.prevent="loginForm()"
                  class="text-left"
                >
                  <v-text-field
                    tabindex="1"
                    outlined
                    dense
                    placeholder=" "
                    label="Usuario"
                    :rules="rules.userName"
                    v-model="user.username"
                    @focus="inputActive = 'username'"
                    @blur="inputActive = ''"
                  />
                  <v-text-field
                    tabindex="2"
                    outlined
                    dense
                    placeholder=" "
                    label="Contraseña"
                    :append-icon="
                      !user.password ? '' : textType ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="() => (textType = !textType)"
                    :type="textType ? 'password' : 'text'"
                    :rules="rules.password"
                    v-model="user.password"
                    @focus="inputActive = 'password'"
                    @blur="inputActive = ''"
                  />
                  <div class="mt-2">
                    <transition name="fade">
                      <v-alert v-if="error" dense elevation="2" type="warning"
                        >Se produjo un error. Intente nuevamente.</v-alert
                      >
                    </transition>
                  </div>
                  <div class="mt-2">
                    <transition name="fade">
                      <v-alert
                        v-if="validationError"
                        dense
                        elevation="2"
                        type="warning"
                        >Usuario o contraseña incorrectos.</v-alert
                      >
                    </transition>
                  </div>
                  <v-btn
                    tabindex="3"
                    :loading="isLoading"
                    color="primary"
                    class="mt-4"
                    :disabled="!isFormValid"
                    type="submit"
                    form="login-form"
                    >Entrar</v-btn
                  >
                </v-form>
              </div>
            </div>
            <div class="copy">
              <p>
                <a
                  href="https://gecros.ar/"
                  style="text-decoration: none;"
                  target="_blank"
                  >GECROS</a
                >
                un producto de
                <a href="https://www.macena.com.ar/" target="_blank"
                  ><img src="@/assets/img/macena.svg" alt="Macena"
                /></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { eVersiones } from "../src/utils/enums";
import { mapActions } from "vuex";

export default {
  name: "Login",
  async mounted() {
    this.getLogo();
    await this.$store.dispatch("AdministracionSistema/getPublicSettings");
    //let color = '#195472';
    if (this.backgroundColor && this.backgroundColor !== "") {
      let css = `#app .login::before { background-color: ${this.backgroundColor} !important; }`,
        head = document.head || document.getElementsByTagName("head")[0];
      let style = document.createElement("style");
      head.appendChild(style);
      style.type = "text/css";
      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
  },
  data() {
    return {
      isLoading: false,
      textType: String,
      isFormValid: true,
      inputActive: "",
      lastVersion: eVersiones.urlVersion,
      currentVersion: eVersiones.current,
      error: false,
      validationError: false,
      user: {
        username: "",
        password: ""
      },
      rules: {
        userName: [
          v => !!v || "*",
          v =>
            (!!v && v.length <= 20 && v.length >= 3) ||
            "Entre 3 y 20 caracteres"
        ],
        password: [
          v => !!v || "*",
          v => (!!v && v.length <= 30) || "Max. 30 caracteres"
        ]
      },
      customLogo: null
    };
  },
  computed: {
    ...mapGetters({
      loginUser: "user/login",
      backgroundColor: "AdministracionSistema/backgroundColor"
    })
  },
  watch: {
    loginUser(val) {
      if (val?.validationError) {
        this.validationError = true;
        this.isLoading = false;
      } else this.validationError = false;
      if (val?.error) {
        this.error = true;
        this.isLoading = false;
      } else this.error = false;

      if (val?.access_token) {
        this.$router.push("/");
      } else {
        this.user.password = "";
        this.isLoading = false;
      }
    }
  },
  methods: {
    ...mapActions({
      getCustomLogo: "AdministracionSistema/getCustomLogo"
    }),
    async getLogo() {
      const response = await this.getCustomLogo();
      if (response.data != "") this.customLogo = response.data;
    },
    loginForm() {
      this.isLoading = true;
      if (this.$refs["login-form"].validate()) {
        try {
          this.isLoading = true;
          // name: 'fuzzyfish',
          // pass: '4a0',
          const userData = {
            user: this.user.username,
            password: this.user.password,
            grand_type: "password",
            client_id: "gecros"
          };
          this.$store.dispatch("user/setLogin", userData);
        } catch (error) {
          this.isLoading = false;
          this.error = true;
          setTimeout(() => {
            this.user.password = "";
            this.error = false;
          }, 5000);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";
.login {
  position: relative;
  height: 100vh;
  width: 100%;
  .full-height {
    height: 90vh;
    @media screen and (max-width: 767px) {
      height: auto;
    }
  }
  &:before {
    width: 50%;
    background: #195472;
    display: block;
    content: "";
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    .right {
      order: -1;
    }
    .copy p {
      margin-top: 20px;
    }
  }
  .logo {
    width: 190px;
    @media screen and (max-width: 767px) {
      width: 100px;
      margin-bottom: 10px;
    }
  }
  .copy {
    font-size: 14px;
    text-align: center;
  }
  .green-column {
    color: #fff;
    @media screen and (max-width: 767px) {
      margin-top: 15px;
      background: #195472;
      padding: 20px 0 10px;
    }
  }
  .white-column {
    color: #424242;
    .login-box {
      .title-sign {
        font-size: 27px;
      }
      .small-logo {
        margin-bottom: 20px;
      }
      padding: 40px 28px;
      border: 1px solid #e0e0e0;
      border-radius: 19px;
      width: 100%;
      max-width: 380px;
    }
  }
  .error {
    font-size: 14px;
    padding: 23px;
    text-align: center;
    background: #fff0f0;
    // color: #cb4e4e;
    border-radius: 6px;
    margin: 8px 0;
    p {
      line-height: 16px;
      margin: 0;
      padding: 0;
    }
  }
}
label {
  color: #9e9e9e;
  font-size: 14px;
  margin: 0 0 6px 16px;
  &.active {
    color: #195472;
  }
}
.input-radio {
  width: 100%;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 4px;
  padding: 8px 12px;
  color: #195472;
  &::placeholder {
    color: #9e9e9e;
  }
  &.active {
    border-color: #195472;
  }
}
.v-btn {
  text-transform: capitalize;
  padding: 6px;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  height: 38px;
}
</style>
